import { Component, Input } from "@angular/core";
import { ProfileModalStep } from "@feature/profile/services/profile-modal-state.service";

@Component({
  selector: "pa-minor-electives-courses",
  templateUrl: "./minor-electives-courses.component.html",
  styleUrls: ["./minor-electives-courses.component.scss"],
})
export class MinorElectivesCoursesComponent {
  @Input() step!: ProfileModalStep;
  protected readonly ProfileModalStep = ProfileModalStep;
}
