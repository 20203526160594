import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiExamenProgrammas } from "@core/api/model/api-examen-programma";
import { Constants } from "@core/constants";
import { Draft } from "@core/domain/draft";
import { StudyProgram } from "@core/domain/study-program";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StudyProgramService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);

  getStudyPrograms(draft: Draft, bachelorOnly = false): Observable<StudyProgram[]> {
    const params = new HttpParams()
      .set("alleen_bachelor", bachelorOnly ? Constants.OPTION_YES : Constants.OPTION_NO)
      .set("opleiding", draft.education)
      .set("start_jaar", draft.startingYear)
      .set("examentype", draft.examType);

    return (
      // prettier-ignore
      this.httpGet<ApiExamenProgrammas>('examenprogrammas', params)
        .pipe(
          map((apiStudyPrograms) => {
            return apiStudyPrograms?.items.map(item => this.mappingService.toStudyProgram(item)) ?? [];
          }),
        )
    );
  }
}
