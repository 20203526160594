import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiEducations } from "@core/api/model/api-education";
import { Education } from "@core/domain/education";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EducationService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);

  getEducations(): Observable<Education[]> {
    const params = new HttpParams().set("plan_app", "true");

    // prettier-ignore
    return this.httpGet<ApiEducations>('opleidingen', params)
      .pipe(
        map(educations => {
          return educations?.items.map(item => this.mappingService.toEducation(item)) ?? [];
        }),
      );
  }
}
