import { inject, Injectable } from "@angular/core";
import { Course } from "@core/domain/course";
import { CurriculumCourse } from "@core/domain/curriculum";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PlanDetails } from "@core/domain/plan-details";
import { CourseData } from "@shared/types/course-data";
import { PlanLaterType } from "@shared/types/plan-later-types";

@Injectable({
  providedIn: "root",
})
export class CourseDataMapper {
  private courseHelper = inject(CourseHelper);

  mapCourseData(
    course: Course | CurriculumCourse,
    selectedPlan?: PlanDetails,
    isPlannable = false,
    isRemovable = true,
    planLaterType?: PlanLaterType,
  ): CourseData {
    const plannedCourse = this.courseHelper.findPlannedCourse(course, selectedPlan);
    const isCoursePlannable = plannedCourse ? false : this.courseHelper.isCoursePlannable(course);
    const isCourseRemovable = isRemovable ? this.courseHelper.isRemovable(course) : false;

    const courseData = {
      ...course,
      courseColor: this.courseHelper.getCourseColor(course),
      hasRibbonPeriods: this.courseHelper.hasRibbonPeriods(course),
      isRibbonCourse: this.courseHelper.isRibbonCourse(course),
      isCourseObtained: this.courseHelper.isCourseObtained(course),
      isCustomCourse: this.courseHelper.isCustomCourse(course),
      isPlannable: isPlannable && isCoursePlannable,
      isPlannedCourse: !!plannedCourse,
      plannedText: plannedCourse ? this.courseHelper.getPlannedText(plannedCourse) : "",
      isRemovable: isCourseRemovable,
      isAddedToPlanLater: false, // Default false, may be updated after this
      isPartOfPlanLater: course.planLater,
      statusObtained: plannedCourse?.statusObtained ?? course.statusObtained,
      statusObtainedLabel: this.courseHelper.getStatusObtainedLabel(course),
      statusRegistered: plannedCourse?.statusRegistered,
      statusRegisteredCurrentPeriod: plannedCourse
        ? this.courseHelper.courseRegisteredForCurrentPeriod(plannedCourse)
        : false,
      planLaterType: planLaterType,
      isMovableToPlanLater: this.courseHelper.isMovableToPlanLater(course, selectedPlan),
      statusStudyYear: plannedCourse?.statusStudyYear ?? course.statusStudyYear,
      periodDescription: plannedCourse?.periodDescription ?? course.periodDescription,
      statusPeriod: plannedCourse?.statusPeriod ?? course.statusPeriod,
      planId: selectedPlan?.id,
    } as CourseData;

    if (selectedPlan) {
      // Course planId may have an undefined value
      courseData.planId = course.planId > 0 ? course.planId : selectedPlan.id;
      courseData.lectureYear = course.lectureYear ?? (selectedPlan.yearNr || 0);
    }

    return courseData;
  }
}
