@if (courseDetail) {
  <ion-header>
    <ion-toolbar color="light">
      <ion-button fill="clear" slot="start">
        <ion-title>
          <b>{{ courseDetail.course }}</b> {{ courseDetail.shortName }}
        </ion-title>
      </ion-button>
      <ion-button fill="clear" (click)="dismiss()" slot="end" [attr.aria-label]="'ariaLabel.closeButton' | translate">
        <ion-icon icon="close" />
      </ion-button>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    @if (isScheduleSelectable) {
      <pa-course-detail
        [courseDetail]="courseDetail"
        [courseData]="courseData"
        isScheduleSelectable
        (selectedPeriod)="selectedPeriod($event)"
        (openCatalog)="openCatalog($event)"
      />
    } @else {
      <pa-course-detail [courseDetail]="courseDetail" [courseData]="courseData" (openCatalog)="openCatalog($event)" />
    }
  </ion-content>

  <ion-footer>
    <ion-toolbar class="ion-padding-horizontal">
      <ion-button slot="start" fill="clear" color="success" href="{{ this.courseDetail.urlOwc }}" target="_blank">
        {{ "courseDetail.buttonMoreInfo" | translate }}
      </ion-button>
      @if (showRemove() && viewStartedBy === Constants.VIEW_STARTED_BY_PLAN) {
        <ion-button slot="end" color="secondary" (click)="removeFromPlanning()">
          {{ "courseDetail.buttonRemoveFromPlanning" | translate }}
        </ion-button>
      }
    </ion-toolbar>
  </ion-footer>
}
