import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiCursusDetail } from "@core/api/model/api-cursus-detail";
import { CourseDetail } from "@core/domain/course-detail";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CourseService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);

  fetchCourse(course: string, planId: number): Observable<CourseDetail | undefined> {
    const params = new HttpParams().set("spla_id", planId).set("cursus", course);

    return this.doFetchCourse(params);
  }

  fetchCourseForYear(year: number, course: string, planId: number): Observable<CourseDetail | undefined> {
    let params = new HttpParams().set("spla_id", planId).set("cursus", course);

    if (year > 0) {
      params = params.set("collegejaar", year);
    }

    return this.doFetchCourse(params);
  }

  private doFetchCourse(params: HttpParams): Observable<CourseDetail | undefined> {
    return (
      // prettier-ignore
      this.httpGet<ApiCursusDetail | undefined>('cursus', params)
        .pipe(
          map(courseDetail => {
            return courseDetail ? this.mappingService.toCourseDetail(courseDetail) : undefined;
          })
        )
    );
  }
}
