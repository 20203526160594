import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "pa-add-course-list-item",
  templateUrl: "./add-course-list-item.component.html",
  styleUrls: ["./add-course-list-item.component.scss"],
})
export class AddCourseListItemComponent {
  @Input() selected = false;
  @Input() selectable = true;
  @Input() disabled = false;
  @Output() selectCourse = new EventEmitter<boolean>();

  constructor() {}

  onToggle(): void {
    this.selected = !this.selected;
    this.selectCourse.emit(this.selected);
  }
}
