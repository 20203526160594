<ion-app>
  <ion-header>
    @if (user) {
      <pa-app-header />
    }
  </ion-header>

  <ion-content>
    <ion-router-outlet></ion-router-outlet>
  </ion-content>
</ion-app>
