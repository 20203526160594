import { Component, Input } from "@angular/core";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PlanHistoryDetailCourse } from "@core/domain/plan-history";

@Component({
  selector: "pa-history-course-item",
  templateUrl: "./history-course-item.component.html",
  styleUrls: ["./history-course-item.component.scss"],
})
export class HistoryCourseItemComponent {
  @Input() course!: PlanHistoryDetailCourse;

  constructor(protected courseHelper: CourseHelper) {}
}
