import { booleanAttribute, Component, inject, Input } from "@angular/core";
import { Constants } from "@core/constants";
import { PopoverController } from "@ionic/angular";
import { CourseAction } from "@shared/types/plan-actions";

@Component({
  selector: "pa-course-action-menu",
  templateUrl: "./course-action-menu.component.html",
  styleUrls: ["./course-action-menu.component.scss"],
})
export class CourseActionMenuComponent {
  @Input({ transform: booleanAttribute }) showPlanNow = false;
  @Input({ transform: booleanAttribute }) showMove = false;
  @Input({ transform: booleanAttribute }) showRemove = false;
  @Input({ transform: booleanAttribute }) showInfo = false;

  private popoverController = inject(PopoverController);

  onAction(action: CourseAction): void {
    this.popoverController.dismiss(action);
  }

  protected readonly Constants = Constants;
}
