import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiPlanDetail, ApiPlanProfilering, ApiStatusResponse } from "@core/api/model/api-plan";
import { Document, PlanDetails, PlanResponse } from "@core/domain/plan-details";
import { PlanProfile, UploadedFileInfo } from "@core/domain/plan-profile";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { PlanMappingService } from "@core/mapping/plan-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { forkJoin, map, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProfileService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);
  private planMapper = inject(PlanMappingService);

  fetchPlanProfile(planId: number): Observable<PlanProfile | undefined> {
    const params = new HttpParams().set("spla_id", planId.toString());
    // prettier-ignore
    return this.httpGet<ApiPlanProfilering>('plan/profilering', params)
      .pipe(
        map(planProfile => {
          return planProfile ? this.mappingService.toPlanProfile(planProfile) : undefined;
        }),
      );
  }

  submitPlanApproval(
    profile: PlanProfile,
    uploadedFiles: UploadedFileInfo[],
    explanation: string,
  ): Observable<PlanResponse> {
    const submitRequest = this.planMapper.mapApiPlanApprovalRequest(profile, uploadedFiles, explanation);
    const url = `plan/${profile.id}/indienen_plan_goedkeuring`;

    // prettier-ignore
    return this.httpPut<ApiPlanDetail | ApiStatusResponse>(url, undefined, JSON.stringify(submitRequest))
      .pipe(
        map(reponse => this.mappingService.toPlanResponse(reponse)),
      );
  }

  uploadDocuments(planDetails: PlanDetails, uploadedFiles: UploadedFileInfo[]): Observable<void> {
    if (planDetails.documents && planDetails.documents.length > 0) {
      this.addDocIdsToUploadedFiles(uploadedFiles, planDetails.documents);
      // prettier-ignore
      const uploads = uploadedFiles
        .map(file => this.uploadDocument(planDetails.id, file, planDetails.studentNr));

      // prettier-ignore
      return forkJoin(uploads)
       .pipe(map(() => {
        // console.log('All documents uploaded')
       }
       ));
    }

    // console.log("No documents to be uploaded");
    return of(void 0);
  }

  private uploadDocument(planId: number, fileInfo: UploadedFileInfo, studentNr: string): Observable<void> {
    const url = `plan/${planId}/indienen_plan_document/${fileInfo.documentId}/document`;

    return this.httpPut(url, undefined, fileInfo.file, this.getStudentNrHeader(studentNr));
  }

  private addDocIdsToUploadedFiles(uploadedFiles: UploadedFileInfo[], documents: Document[]): void {
    documents.forEach((document) => {
      const uploadedFile = uploadedFiles.find(
        (fileInfo) =>
          fileInfo.file.name === document.documentName &&
          fileInfo.file.size === document.documentSize &&
          fileInfo.documentId === undefined,
      );
      if (uploadedFile) {
        uploadedFile.documentId = document.documentId;
      }
    });
  }
}
