import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "pa-course-action-button",
  templateUrl: "./course-action-button.component.html",
  styleUrls: ["./course-action-button.component.scss"],
})
export class CourseActionButtonComponent {
  @Input() icon!: string;
  @Input() iconColor: "primary" | "success" = "success"; // default is green
  @Input() active!: boolean;
  @Input() tooltip = "";
  @Input() size: "large" | "normal" = "normal"; //default is normal
  @Output() action = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.action.emit(event);
  }

  getActiveIconColor(): string | undefined {
    if (this.active && this.iconColor === "success") {
      return "active-success";
    }
    if (this.active && this.iconColor === "primary") {
      return "active-primary";
    }

    return undefined;
  }
}
