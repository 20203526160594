import { Component, computed, inject } from "@angular/core";
import { Constants } from "@core/constants";
import { PlanHelper } from "@core/domain/helpers/plan-helper";
import { PlanProfile } from "@core/domain/plan-profile";
import { ProfileModalStateService } from "@feature/profile/services/profile-modal-state.service";
import { TranslateService } from "@ngx-translate/core";
import { GenericDataList } from "@shared/components/generic-data-table/generic-data-table.component";
import { PlanStateService } from "@shared/services/plan-state.service";

@Component({
  selector: "pa-minor-electives-confirm-step",
  templateUrl: "./minor-electives-confirm-step.component.html",
  styleUrls: ["./minor-electives-confirm-step.component.scss"],
})
export class MinorElectivesConfirmStepComponent {
  private profileModalStateService = inject(ProfileModalStateService);
  private planStateService = inject(PlanStateService);
  private planHelper = inject(PlanHelper);
  private translate = inject(TranslateService);

  approveRemark = "";
  uploadBtnDisabled = false;
  selectedFiles: File[] = [];

  profile = this.profileModalStateService.profile;
  warnings = this.profileModalStateService.warnings;
  dataList = computed<GenericDataList[]>(() => {
    // Re-initialize the dataList
    const profile = this.profile();
    this.profileModalStateService.determineSubmitDisabled();
    return profile ? this.buildTable(profile) : [];
  });
  private totalPoints = this.profileModalStateService.totalPoints;
  private selectedPoints = this.profileModalStateService.selectedPoints;

  readonly remarkMaxLength = Constants.REMARK_MAX_LENGTH;

  isUploadPossible(): boolean {
    // The field documentToUpload SHOULD contain only 'V' (mandatory) or 'O' (optional).
    // However, the old codebase shows that values 'Y' or 'N' are also possible.
    // 'N' means "no upload possible". 'Y' is no longer valid.

    return this.planHelper.isUploadPossible(this.planStateService.currentSelectedPlan()!);
  }

  onFileChange(fileChangeEvent: Event): void {
    // @ts-expect-error  files not recognized within target
    const files = fileChangeEvent.target.files;
    this.addToSelectedFiles(files);
    this.profileModalStateService.setUploadedFiles(this.selectedFiles);
  }

  openFile(file: File): void {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  removeFile(index: number): void {
    this.selectedFiles.splice(index, 1);
    this.profileModalStateService.setUploadedFiles(this.selectedFiles);
    this.clearServiceErrorForFileExtension(); // with remove => clear warnings array with code=12 (wrong file type upload)
  }

  isUploadRequired(): boolean {
    return this.profile()!.documentToUpload === Constants.OPTION_MANDATORY;
  }

  clearServiceErrorForFileExtension(): void {
    //TODO verder uitwerken
    // let newList: Array<{ warning: Statusmessage, show: Function }> = new Array<{ warning: Statusmessage, show: Function }>();
    // this.warnings.forEach((warningMessage: any) => {
    //   let sm: Statusmessage = warningMessage.warning;
    //   if (sm.code.toString() !== '12') { // this is error code for upload of documents with wrong file extension.
    //     newList.push(warningMessage);
    //   }
    // });
    // this.warnings = newList;
  }

  private buildTable(profile: PlanProfile): GenericDataList[] {
    const list: GenericDataList[] = [];

    if (profile.minimumPoints > 0) {
      list.push({
        label: `${this.translate.instant("choiceMinorOrElectives.amountRequiredEcts")}:`,
        value: profile.minimumPoints,
      });
    }
    if (profile.maximumPoints > 0) {
      list.push({
        label: `${this.translate.instant("choiceMinorOrElectives.amountMaxEcts")}:`,
        value: profile.maximumPoints,
      });
    }
    list.push({
      label: `${this.translate.instant("choiceMinorOrElectives.amountSelectedEcts")}:`,
      value: this.selectedPoints() ?? 0,
      whitespace: true,
    } as GenericDataList);
    list.push({
      label: `${this.translate.instant("choiceMinorOrElectives.totaalGepland")}:`,
      value: this.totalPoints() ?? 0,
    } as GenericDataList);

    if (profile.categoryRequirements.length > 0) {
      let whitespace = true;
      for (const category of profile.categoryRequirements) {
        list.push({
          label: `${category.categoryDescription}:`,
          value: `${this.profileModalStateService.getCategoryCount(category)}`,
          whitespace,
        });
        whitespace = false;
      }
    }

    return list;
  }

  maxUploadCountReached(): boolean {
    return this.selectedFiles.length >= this.profile()!.maxDocumentsToSubmit;
  }

  onApproveRemarkChange(): void {
    this.profileModalStateService.approveRemark = this.approveRemark;
    this.profileModalStateService.determineSubmitDisabled();
  }

  private addToSelectedFiles(files: File[]) {
    const selectedFiles = [...this.selectedFiles, ...files];

    // Make sure that the selectedFiles array contains only files with unique names
    this.selectedFiles = [...new Map(selectedFiles.map((item) => [item.name, item])).values()];
  }
}
