import { OnChanges, Component, Input } from "@angular/core";
import { PlanHistoryDetails, PlanHistoryItem } from "@core/domain/plan-history";
import { TranslateService } from "@ngx-translate/core";
import { GenericDataList } from "@shared/components/generic-data-table/generic-data-table.component";

@Component({
  selector: "pa-history-details",
  templateUrl: "./history-details.component.html",
  styleUrls: ["../../../components/generic-data-table/generic-data-table.component.scss"],
})
export class HistoryDetailsComponent implements OnChanges {
  @Input() historyItem!: PlanHistoryItem;
  @Input() historyDetails!: PlanHistoryDetails;
  dataList: GenericDataList[] = [];

  constructor(private translate: TranslateService) {}

  ngOnChanges(): void {
    this.dataList = this.buildList();
  }
  getStatusText(): string {
    return `${this.historyItem.statusDescription} (${this.historyDetails.decisionDate})`;
  }

  private buildList(): GenericDataList[] {
    const list: GenericDataList[] = [];

    list.push({
      label: `${this.translate.instant("schedule.history.numberOfCreditsRequired")} ${
        this.historyDetails.profileName
      }:`,
      value: this.historyDetails.profileMinimumPoints,
    });
    list.push({
      label: `${this.translate.instant("schedule.history.numberOfCreditsSelected")}:`,
      value: this.historyDetails.pointsSelected,
      whitespace: true,
    });
    for (const category of this.historyDetails.pointsPerCategory) {
      list.push({
        label: `${category.categoryDescription}:`,
        value: `${category.pointsSelected}`,
      });
    }
    list.push({
      label: `${this.translate.instant("schedule.history.assessmentMoment")}:`,
      value: this.historyDetails.reviewMomentDescription,
      whitespace: true,
    });
    list.push({
      label: `${this.translate.instant("label.status")}:`,
      value: this.getStatusText(),
    });
    if (this.historyDetails.studentExplanation) {
      list.push({
        full: true,
        label: `${this.translate.instant("schedule.history.explanationStudent")} ${
          this.historyDetails.submitDate ? `(${this.historyDetails.submitDate})` : ""
        }:`,
        value: this.historyDetails.studentExplanation,
        whitespace: true,
      });
    }
    if (this.historyDetails.adviceExplanation) {
      list.push({
        full: true,
        label: `${this.translate.instant("schedule.adviceGivenBy")}:
        ${this.historyDetails.adviceTitle ? `${this.historyDetails.adviceTitle}` : ""}
        ${this.historyDetails.adviceDate ? `(${this.historyDetails.adviceDate})` : ""}`,
        value: this.historyDetails.adviceExplanation,
        whitespace: true,
      });
    }
    if (this.historyDetails.decisionExplanation) {
      list.push({
        full: true,
        label: `${this.translate.instant("schedule.decisionMadeBy")}:
        ${this.historyDetails.decisionTitle ? `${this.historyDetails.decisionTitle}` : ""}
        ${this.historyDetails.decisionDate ? `(${this.historyDetails.decisionDate})` : ""}`,
        value: this.historyDetails.decisionExplanation,
        whitespace: true,
      });
    }

    return list;
  }
}
