<div class="course-list-item">
  <ion-checkbox
    class="list-checkbox ion-focusable"
    [checked]="selected"
    (ionChange)="onToggle()"
    [disabled]="disabled || (!selectable && !selected)"
  />
  <div class="list-item">
    <ng-content />
  </div>
</div>
