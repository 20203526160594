<ng-container>
  @switch (step) {
    @case (ProfileModalStep.Choice) {
      <pa-minor-electives-choice-step />
    }
    @case (ProfileModalStep.Confirm) {
      <pa-minor-electives-confirm-step />
    }
    @default {
      <p>Oops...</p>
    }
  }
</ng-container>
