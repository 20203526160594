<ng-container>
  <ion-card
    button="true"
    [id]="itemId"
    class="course-card"
    [ngClass]="{ 'course-planned': courseData.yearNr > 0 && courseData.periodDescription !== '' }"
    [ngStyle]="{ 'border-left-color': determineCourseSideColor() }"
    (mouseenter)="hoverItem = true"
    (mouseleave)="hoverItem = false"
  >
    <ion-card-content class="course-lines">
      <div class="course-line-wrap">
        <div
          class="course-line"
          [ngClass]="{ 'course-obtained-tooltip': courseData.statusObtained !== undefined }"
          [title]="courseObtainedTooltip"
        >
          @if (courseData.isRibbonCourse) {
            <ion-icon class="course-link-icon" icon="link" />
          }
          <span class="course-name">
            @if (courseData.code) {
              <b>{{ courseData.code }}</b>
            }
            {{ courseHelper.getCourseName(courseData) }}
            @if (courseData.replaceOld) {
              ({{ "course.replaceCourse" | translate }} {{ courseData.replaceOld }})
            }
            @if (courseData.statusObtained === true) {
              <span class="course-status-obtained">&check;</span>
            } @else if (courseData.statusObtained === false) {
              <span class="course-status-not-obtained">&#33;</span>
            }
          </span>
        </div>
        <div class="filler"></div>
        <div class="course-line">
          @if (courseData.statusRegistered) {
            @if (courseData.statusRegisteredCurrentPeriod) {
              <span class="course-status-registered">
                {{ "planLater.registered" | translate }}
              </span>
            } @else if (courseData.statusStudyYear) {
              <span class="course-status-registered-other-period">
                {{ "planLater.registered" | translate }} in {{ "course.year" | translate }}
                {{ courseData.statusStudyYear }} - {{ courseData.periodDescription }}
                {{ courseData.statusPeriod }}
              </span>
            }
          }
        </div>
        <div class="course-line">
          @if (courseData.plannedText) {
            <span class="course-status-planned">{{ courseData.plannedText }}</span>
          }
          @if (showPlanLaterButton && courseData.isAddedToPlanLater) {
            <span class="course-status-planned">
              {{ "course.inPlanLater" | translate }}
            </span>
          }
        </div>
      </div>

      <div class="course-line">
        @if (courseData.studyPoints) {
          <pa-study-points [points]="courseData.studyPoints" />
        }
        @if (!hideTimeslots && courseData.timeslots.length > 0) {
          <div class="timeslots">
            @for (timeslot of courseData.timeslots; track timeslot) {
              <pa-timeslot class="timeslot-spacing" [timeslot]="timeslot" />
            }
          </div>
        }

        <span class="filler"></span>
        @if (showCategory && courseData.categoryDescription) {
          <span class="course-status-planned">{{ courseData.categoryDescription }}</span>
        }

        <ng-template [ngxPermissionsExcept]="Constants.ROLE_READONLY_EMPLOYEE">
          @if (showActions) {
            <div class="course-actions">
              @if (showPlanLaterButton && !courseData.isPartOfPlanLater && courseData.yearNr === 0) {
                <ion-button
                  (click)="onPlanLater($event)"
                  class="course-action-button"
                  color="secondary"
                  [attr.aria-label]="'label.planLater' | translate"
                >
                  {{ "label.planLater" | translate }}
                </ion-button>
              }

              @if (courseData.isPlannable) {
                <ion-button
                  (click)="onPlanNow($event)"
                  class="course-action-button"
                  color="secondary"
                  [attr.aria-label]="'label.planNow' | translate"
                >
                  {{ "label.planNow" | translate }}
                </ion-button>
              }

              @if (courseData.isRemovable) {
                <pa-course-action-button
                  icon="trash"
                  size="large"
                  [active]="hoverItem"
                  tooltip="label.remove"
                  (action)="onRemoveCourse($event)"
                />
              }
            </div>
          }
        </ng-template>

        @if (!hideShowInfo && !this.courseData.ownCourse) {
          <pa-course-action-button
            icon="information-circle"
            size="large"
            [active]="hoverItem"
            tooltip="courseControl.showInfo"
            (action)="onCourseInfo($event)"
            [attr.aria-label]="'courseControl.showInfo' | translate"
          />
        }
      </div>
    </ion-card-content>
  </ion-card>
</ng-container>
