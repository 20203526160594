<ion-button
  fill="clear"
  color="primary"
  class="action-menu-button"
  (click)="onAction($event)"
  [title]="tooltip | translate"
>
  <ion-label [attr.aria-label]="tooltip | translate" />
  <ion-icon [size]="size" [icon]="icon" [ngClass]="getActiveIconColor()" />
</ion-button>
