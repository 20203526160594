import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { PlanHistoryService } from "@core/services/plan-history.service";

@Component({
  selector: "pa-download-file",
  templateUrl: "./download-file.component.html",
  styleUrls: ["./download-file.component.scss"],
})
export class DownloadFileComponent {
  @Input() fileName!: string;
  @Input() documentId!: number;
  @Input() studentNr!: string;

  @ViewChild("downloadLink") downloadLink!: ElementRef<HTMLAnchorElement>;

  constructor(private planHistoryService: PlanHistoryService) {}

  openDocument(event: MouseEvent): void {
    event.preventDefault();

    this.planHistoryService
      .getDocument(this.documentId, this.studentNr)
      //
      .subscribe((blob) => {
        this.downloadLink.nativeElement.href = window.URL.createObjectURL(blob);
        this.downloadLink.nativeElement.download = this.fileName;
        this.downloadLink.nativeElement.click();

        // Clear the temp download url
        this.downloadLink.nativeElement.href = "";
      });
  }
}
