import { Component, Input } from "@angular/core";
import { PlanHistory } from "@core/domain/plan-history";
import { ModalController } from "@ionic/angular";
import { Constants } from "@core/constants";

@Component({
  selector: "pa-show-history-modal",
  templateUrl: "./show-history-modal.component.html",
  styleUrls: ["./show-history-modal.component.scss"],
})
export class ShowHistoryModalComponent {
  @Input() title!: string;
  @Input() history: PlanHistory | undefined;

  constructor(private modalCtrl: ModalController) {}

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);
  }
}
