<ion-modal [isOpen]="isOpen" (didPresent)="onDidPresent()" (didDismiss)="onDidDismiss()">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-button fill="clear" slot="start">
          <ion-title>
            {{ title }}
          </ion-title>
        </ion-button>
        <ion-button
          class="confirm-cancel"
          slot="end"
          (click)="cancel()"
          fill="clear"
          color="primary"
          [attr.aria-label]="'ariaLabel.closeButton' | translate"
        >
          <ion-icon icon="close" />
        </ion-button>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-searchbar #searchbar [debounce]="300" (ionInput)="filterItems($event)" tappable="true" />
      @for (item of filtered; track $index) {
        <ion-item (click)="itemSelected(item)">
          <ion-label>{{ item.value }}</ion-label>
        </ion-item>
      }
    </ion-content>
  </ng-template>
</ion-modal>
