import { ApiStatusmelding } from "@core/api/model/api-statusmelding";
import { CaciBoolean } from "@core/api/model/api-types";
import { Constants } from "@core/constants";
import { StatusMessage } from "@core/domain/status-message";

export class ApiHelper {
  constructor() {}

  static caciBooleanToBoolean(value: CaciBoolean): boolean {
    return value === "J";
  }

  static caciBooleanToBooleanOrUndefined(value: CaciBoolean): boolean | undefined {
    if (value === undefined || value === null || value.length === 0) {
      return undefined;
    }

    return value === "J";
  }

  static booleanToCaciBoolean<T>(value: T): CaciBoolean {
    if (value === undefined || value === null) {
      return undefined;
    }

    return value ? "J" : "N";
  }

  static stringListToArray(value: string, separator = Constants.SEPARATOR_COMMA): string[] {
    return value ? value.split(separator).map((str) => str.trim()) : [];
  }

  static numberListToArray(value: string): number[] {
    return value ? value.split(Constants.SEPARATOR_COMMA).map((str) => Number(str.trim())) : [];
  }

  static numberArrayToString(value: number[]): string {
    // prettier-ignore
    return value
      ?.map(num => num.toString())
      .join(Constants.SEPARATOR_COMMA) ?? '';
  }

  static toStatusMessage(msg: ApiStatusmelding): StatusMessage {
    return {
      code: msg.code,
      type: msg.type,
      columns: this.stringListToArray(msg.kolom),
      message: msg.tekst,
    } as StatusMessage;
  }
}
