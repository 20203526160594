import { Injectable } from "@angular/core";
import { ApiCursus } from "@core/api/model/api-cursus";
import { ApiVoorwaarde } from "@core/api/model/api-examenonderdeel";
import {
  ApiCategoryRequirement,
  ApiCursusRequest,
  ApiDocument,
  ApiDocumentRequest,
  ApiMinorExamenonderdeelRequest,
  ApiMinorRequest,
  ApiPlanApprovalRequest,
  ApiPlanDetail,
  ApiPlanPeriode,
  ApiPlanProfilering,
  ApiStudiejaar,
} from "@core/api/model/api-plan";
import { ApiProfielExamenonderdeel, ApiProfielMinor } from "@core/api/model/api-plan-later";
import { Course } from "@core/domain/course";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { StatusMessageHelper } from "@core/domain/helpers/status-message-helper";
import { Document, Period, PlanDetails, StudyYear } from "@core/domain/plan-details";
import {
  CategoryRequirement,
  Condition,
  MinorProfile,
  PlanProfile,
  ProfileMinorExamComponent,
  UploadedFileInfo,
} from "@core/domain/plan-profile";

@Injectable({
  providedIn: "root",
})
export class PlanMappingService {
  private plan!: PlanDetails;
  private periodDescr = "";

  constructor(
    private courseHelper: CourseHelper,
    private messageHelper: StatusMessageHelper,
  ) {}

  mapPlan(apiPlan: ApiPlanDetail): PlanDetails {
    this.plan = {
      reviewMoment: apiPlan.beoordelingsmoment,
      draft: ApiHelper.caciBooleanToBoolean(apiPlan.concept_planning),
      credits: apiPlan.studiepunten_behaald,
      creditsPlannedPast: (apiPlan.studiepunten_gepland_totaal ?? 0) - (apiPlan.studiepunten_gepland ?? 0), // Zie old code, comment OB-9354
      creditsTotal: apiPlan.studiepunten_behaald_totaal,
      creditsTotalPlanned: apiPlan.studiepunten_gepland_totaal,
      decisionDate: apiPlan.datum_besluit,
      decisionExplanation: apiPlan.toelichting_besluit,
      documentToUpload: apiPlan.document_uploaden,
      documents: apiPlan.documenten?.map((document) => this.mapDocument(document)),
      degreeProgram: apiPlan.opleiding,
      examStage: apiPlan.examenfase,
      studyProgram: apiPlan.examenprogramma,
      examType: apiPlan.examentype,
      examTypeCode: apiPlan.examentype_code,
      secondExamStage: apiPlan.tweede_examenfase,
      secondExamTypeCode: apiPlan.tweede_examentype_code,
      secondStudyProgram: apiPlan.tweede_examenprogramma,
      hasSpecialisations: ApiHelper.caciBooleanToBoolean(apiPlan.heeft_specialisaties),
      id: apiPlan.spla_id,
      lastSaved: apiPlan.laatst_opgeslagen,
      name: apiPlan.planning_naam,
      pointsPlanned: apiPlan.studiepunten_gepland,
      profileName: apiPlan.profil_naam,
      scheduleMinor: ApiHelper.caciBooleanToBoolean(apiPlan.kan_minor_inplannen),
      staffMember: apiPlan.medewerker,
      startingYear: apiPlan.aanvangsjaar_student,
      statusMessages: undefined,
      studentNr: apiPlan.studentnummer,
      studentName: apiPlan.studentnaam,
      studyYears: this.mapStudyYears(apiPlan.studiejaren),
      submitMinPointsObtained: apiPlan.indienen_min_punten_behaald,
      submitMinPointsPlanned: apiPlan.indienen_min_punten_gepland,
      yearCount: apiPlan.aantal_jaar,
      yearNr: apiPlan.start_jaar,
      currentYear: apiPlan.huidige_jaar,
      submitExplanation: apiPlan.toelichting_indienen,
      planResubmit: ApiHelper.caciBooleanToBoolean(apiPlan.opnieuw_indienen),
      planSubmit: ApiHelper.caciBooleanToBoolean(apiPlan.planning_indienen),
      planState: apiPlan.status,
      statusBy: apiPlan.status_door,
      statusAdviceBy: apiPlan.status_advies_door,
      submitDate: apiPlan.datum_indienen,
      adviceState: apiPlan.status_advies,
      adviceDate: apiPlan.datum_advies,
      adviceExplanation: apiPlan.toelichting_advies,
      validationAllowed: ApiHelper.caciBooleanToBoolean(apiPlan.is_validatie_toegestaan),
      notResubmittingDiploma: apiPlan.niet_opnieuw_indienen_bij_dipl_aanvr,
      diplomaApplicationState: apiPlan.student_diploma_aanvraag_status,
    } as PlanDetails;

    this.extractStatusMessages(apiPlan);
    this.determinePeriodDescription();

    this.plan.studyYears.forEach((year) => {
      this.enrichLintedCourses(year);
      this.enrichCourses(this.plan.id, year);
    });

    return this.plan;
  }

  mapDocument(document: ApiDocument): Document {
    return {
      documentName: document.bestandsnaam,
      documentSize: document.document_grootte,
      documentId: document.doc_id,
    } as Document;
  }

  private mapStudyYears(studiejaren: ApiStudiejaar[]) {
    // prettier-ignore
    return studiejaren
      ?.filter(year => year.periodes && year.periodes.length > 0)
      ?.map(year => this.mapYear(year)) ?? []
  }

  private mapYear(studiejaar: ApiStudiejaar): StudyYear {
    return {
      yearNr: studiejaar.studiejaar,
      lectureYear: studiejaar.collegejaar,
      lectureYearDescription: studiejaar.collegejaar_oms,
      periods: studiejaar.periodes.map((periode) => this.mapPeriod(periode, studiejaar.studiejaar)),
    } as StudyYear;
  }

  private mapPeriod(periode: ApiPlanPeriode, yearNr: number): Period {
    return {
      periodNr: periode.periode_nr,
      periodDescription: periode.periode_oms,
      actual: ApiHelper.caciBooleanToBoolean(periode.actueel),
      courses: periode.cursussen.map((cursus) => {
        const periodDescription = this.courseHelper.stripPeriodDescriptionNumber(periode.periode_oms);
        return this.mapCourse(cursus, yearNr, periode.periode_nr, periodDescription);
      }),
    } as Period;
  }

  private mapCourse(cursus: ApiCursus, yearNr = 0, periodNr = 0, periodDescr = ""): Course {
    return {
      id: cursus.spcu_id,
      code: cursus.cursus,
      name: cursus.korte_naam,
      fullName: cursus.lange_naam,
      startingBlock: cursus.aanvangsblok,
      timeslots: ApiHelper.stringListToArray(cursus.timeslots),
      ownCourse: cursus.eigen_cursus?.trim(),
      ownCoursePoints: cursus.eigen_cursus_punten,
      studyProgram: cursus.examenprogramma,
      examComponent: cursus.examenonderdeel,
      planLater: ApiHelper.caciBooleanToBoolean(cursus.plan_later),
      statusObtained: ApiHelper.caciBooleanToBooleanOrUndefined(cursus.status_behaald),
      statusRegistered: ApiHelper.caciBooleanToBoolean(cursus.status_ingeschreven),
      ribbonPeriods: ApiHelper.numberListToArray(cursus.lint_periodes),
      statusStudyYear: cursus.status_studiejaar,
      statusPeriod: cursus.status_periode,
      replaceOld: cursus.vervanging_oud,
      replaceNew: cursus.vervanging_nieuw,
      label: cursus.label,
      color: cursus.kleur,
      statusValidated: cursus.status_goedgekeurd,
      studyPoints: cursus.studiepunten,
      isFlexCourse: ApiHelper.caciBooleanToBoolean(cursus.flex_cursus),
      statusMessages: [],

      category: cursus.categorie,
      categoryDescription: cursus.categorie_oms,
      minorElsewhere: ApiHelper.caciBooleanToBoolean(cursus.bijvak_elders),
      minorPointsElsewhere: cursus.bijvak_punten_elders,
      sorting1: cursus.sortering_1,
      sorting2: cursus.sortering_2,
      sorting3: cursus.sortering_3,

      // Transient fields:
      lectureYear: 0,
      planId: 0,
      isRibbon: false,
      yearNr, // If set, the course has been planned
      periodNr,
      periodDescription: periodDescr,
      ribbonNr: 1,
    } as Course;
  }

  private extractStatusMessages(apiPlan: ApiPlanDetail) {
    if (apiPlan.statusmeldingen) {
      const messages = apiPlan.statusmeldingen
        .sort((a, b) => a.kolom.localeCompare(b.kolom))
        .map((msg) => ApiHelper.toStatusMessage(msg));
      if (messages) {
        this.plan.statusMessages = messages.filter((msg) => this.messageHelper.isGenericStatusMessage(msg));
      }

      for (const studiejaar of this.plan.studyYears) {
        for (const period of studiejaar.periods) {
          period.statusMessages = messages.filter((msg) =>
            this.messageHelper.isPeriodStatusMessage(msg, studiejaar.yearNr, period.periodNr),
          );

          for (const course of period.courses) {
            course.statusMessages = messages.filter((msg) =>
              this.messageHelper.isCourseStatusMessage(msg, studiejaar.yearNr, period.periodNr, course.code),
            );
          }
        }
      }
    }
  }

  private enrichLintedCourses(year: StudyYear): void {
    const ribbonCourses: Course[] = [];

    year.periods.forEach((period) => {
      period.courses.forEach((course) => {
        if (this.courseHelper.hasRibbonPeriods(course)) {
          ribbonCourses.push(course);
        }
      });
    });

    ribbonCourses.forEach((course) => {
      this.courseHelper.planRibbonCourses(this.plan, course);
    });
  }

  private enrichCourses(id: number, year: StudyYear) {
    year.periods.forEach((period) => {
      period.courses.forEach((course: Course) => {
        course.planId = id;
        course.lectureYear = year.lectureYear;
        course.periodNr = period.periodNr;
        course.periodDescription = this.periodDescr;
      });

      period.courses = this.courseHelper.sortCourses(period.courses);
    });
  }

  private determinePeriodDescription(): void {
    // Determine period oms to show in toast after course is planned
    if (this.plan.studyYears.length > 0 && this.plan.studyYears[0].periods.length > 0) {
      this.periodDescr = this.courseHelper.stripPeriodDescriptionNumber(
        this.plan.studyYears[0].periods[0].periodDescription,
      );
    }
  }

  //
  // ------------- Plan Profiling ----------------
  //
  mapPlanProfile(apiPlanProfilering: ApiPlanProfilering): PlanProfile {
    apiPlanProfilering.studiejaren = [];

    // prettier-ignore
    return {
      ...this.mapPlan(apiPlanProfilering),
      profileName: apiPlanProfilering.profil_naam,
      draftPlan: ApiHelper.caciBooleanToBoolean(apiPlanProfilering.concept_planning),
      minorRequirementType: apiPlanProfilering.profil_minor_eis_type,
      minorRequirementTypeDescription: apiPlanProfilering.profil_minor_eis_type_oms,
      minimumPoints: apiPlanProfilering.profil_minimum_punten,
      maximumPoints: apiPlanProfilering.profil_maximum_punten,
      textSpecify: apiPlanProfilering.tekst_opgeven_profilering,
      textConfirm: apiPlanProfilering.tekst_bevestig_profilering,
      textConfirmUpload: apiPlanProfilering.tekst_bevestig_profilering_upload,
      maxDocumentsToSubmit: apiPlanProfilering.max_documenten_indienen,
      categoryRequirements: apiPlanProfilering.categorie_eisen?.map(eis => this.mapCategoryRequirement(eis)) ?? [],
      individualArrangements: apiPlanProfilering.individuele_regelingen?.map(cursus => this.mapCourse(cursus)) ?? [],
      minors: apiPlanProfilering.minors?.map(minor => this.mapMinor(minor)) ?? [],
      courses: apiPlanProfilering.losse_cursussen?.map(cursus => this.mapCourse(cursus)) ?? [],
    };
  }

  private mapCategoryRequirement(categorieEis: ApiCategoryRequirement): CategoryRequirement {
    return {
      category: categorieEis.categorie,
      categoryDescription: categorieEis.categorie_oms,
      maximumPoints: categorieEis.maximum_punten,
      minimumPoints: categorieEis.minimum_punten,
    } as CategoryRequirement;
  }

  private mapMinor(apiMinor: ApiProfielMinor): MinorProfile {
    return {
      minor: apiMinor.minor,
      name: apiMinor.naam,
      type: apiMinor.type,
      examComponent: apiMinor.examenonderdeel,
      examComponents: apiMinor.examenonderdelen.map((comp) => this.mapMinorExamComponent(comp)),

      code: apiMinor.code,

      studyProgram: apiMinor.examenprogramma,
      content: apiMinor.inhoud,
      typeBama: apiMinor.type_bama,
      typePart: apiMinor.type_onderdeel,
      statusRegistered: ApiHelper.caciBooleanToBoolean(apiMinor.status_ingeschreven),
      minorStudyProgram: apiMinor.minor_examenprogramma,

      maxLength: 400,
      moreDetail: false,
      limited: true,
      inPlanLater: false,
      _source: apiMinor._source,
      _id: apiMinor._id,
      minimumPoints: apiMinor.minimum_punten,
    } as MinorProfile;
  }

  private mapMinorExamComponent(component: ApiProfielExamenonderdeel): ProfileMinorExamComponent {
    return {
      id: component.sple_id,
      minorExamComponent: component.minor_examenonderdeel,
      minorExamComponentName: component.minor_onderdeel_naam,
      minorExamComponentExplanation: component.examenonderdeel_toelichting,
      label: component.label,
      color: component.kleur,
      type: component.type,
      typeMandatoryChoice: component.type_vk,
      minimumPoints: component.minimum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(component.voldaan),
      conditionsMetMandatoryOrMandatoryChoice: ApiHelper.caciBooleanToBoolean(component.voldaan_vk),
      componentTypes: component.onderdeel_types,

      examComponentName: component.examenonderdeel_naam,
      studyProgram: component.examenprogramma,
      labelExaminationProgram: component.label_examenprogramma,
      examComponent: component.examenonderdeel,
      examComponentExplanation: component.examenonderdeel_toelichting,
      conditions: component.voorwaarden?.map((voorwaarde) => this.mapCondition(voorwaarde)),
      courses: component.cursussen.map((cursus: ApiCursus) => this.mapCourse(cursus)),
    } as ProfileMinorExamComponent;
  }

  private mapCondition(voorwaarde: ApiVoorwaarde): Condition {
    return {
      section: voorwaarde.rubriek,
      sectionDescription: voorwaarde.rubriek_oms,
      sectionNumber: voorwaarde.volgnummer_rubriek,
      freeField: voorwaarde.vrij_veld,
      freeFieldDescription: voorwaarde.vrij_veld_oms,
      content: voorwaarde.inhoud,
    } as Condition;
  }

  mapApiPlanApprovalRequest(
    profile: PlanProfile,
    uploadedFiles: UploadedFileInfo[],
    explanation: string,
  ): ApiPlanApprovalRequest {
    return {
      spla_id: profile.id,
      toelichting_indienen: explanation,
      individuele_regelingen: profile.individualArrangements?.map((course) => this.mapApiCursusRequest(course)) ?? [],
      minors: profile.minors?.map((minor) => this.mapApiMinorRequest(minor)) ?? [],
      losse_cursussen: profile.courses?.map((course) => this.mapApiCursusRequest(course)) ?? [],
      documenten: uploadedFiles?.map((document) => this.mapApiDocumentRequest(document)) ?? [],
    } as ApiPlanApprovalRequest;
  }

  private mapApiMinorRequest(minor: MinorProfile): ApiMinorRequest {
    return {
      minor: minor.minor,
      minor_examenprogramma: minor.minorStudyProgram,
      examenonderdelen: minor.examComponents.map((comp) => this.mapApiMinorExamenonderdeelRequest(comp)),
    };
  }

  private mapApiMinorExamenonderdeelRequest(component: ProfileMinorExamComponent): ApiMinorExamenonderdeelRequest {
    return {
      sple_id: component.id,
      cursussen: component.courses.map((course) => this.mapApiCursusRequest(course)),
    };
  }

  private mapApiCursusRequest(course: Course): ApiCursusRequest {
    return {
      spcu_id: course.id,
    } as ApiCursusRequest;
  }

  private mapApiDocumentRequest(document: UploadedFileInfo): ApiDocumentRequest {
    return {
      bestandsnaam: document.file.name,
      document_grootte: document.file.size,
    } as ApiDocumentRequest;
  }
}
