import { Component, computed, inject } from "@angular/core";
import { PlanProfile } from "@core/domain/plan-profile";
import { ProfileModalStateService } from "@feature/profile/services/profile-modal-state.service";
import { TranslateService } from "@ngx-translate/core";
import { GenericDataList } from "@shared/components/generic-data-table/generic-data-table.component";

@Component({
  selector: "pa-minor-electives-choice-step",
  templateUrl: "./minor-electives-choice-step.component.html",
  styleUrls: ["./minor-electives-choice-step.component.scss"],
})
export class MinorElectivesChoiceStepComponent {
  private profileModalStateService = inject(ProfileModalStateService);
  private translate = inject(TranslateService);

  private profile = this.profileModalStateService.profile;
  private selectedPoints = this.profileModalStateService.selectedPoints;
  private totalPoints = this.profileModalStateService.totalPoints;
  warnings = this.profileModalStateService.warnings;
  dataList = computed<GenericDataList[]>(() => {
    // Re-initialize the dataList
    const profile = this.profile();
    return profile ? this.buildTable(profile) : [];
  });

  private buildTable(profile: PlanProfile): GenericDataList[] {
    const list: GenericDataList[] = [];
    if (profile.textSpecify.trim().length > 0) {
      list.push({
        label: profile.textSpecify,
        full: true,
      } as GenericDataList);
    }
    if (profile.minimumPoints > 0) {
      list.push({
        label: `${this.translate.instant("choiceMinorOrElectives.amountRequiredEcts")}:`,
        value: profile.minimumPoints,
        whitespace: true,
      });
    }
    if (profile.maximumPoints > 0) {
      list.push({
        label: `${this.translate.instant("choiceMinorOrElectives.amountMaxEcts")}:`,
        value: profile.maximumPoints,
      });
    }
    list.push({
      label: `${this.translate.instant("choiceMinorOrElectives.amountSelectedEcts")}:`,
      value: this.selectedPoints() ?? 0,
      whitespace: true,
    } as GenericDataList);
    list.push({
      label: `${this.translate.instant("choiceMinorOrElectives.totaalGepland")}:`,
      value: this.totalPoints() ?? 0,
    } as GenericDataList);

    if (profile.categoryRequirements.length > 0) {
      let whitespace = true;
      for (const category of profile.categoryRequirements) {
        list.push({
          label: `${category.categoryDescription}:`,
          value: `${this.profileModalStateService.getCategoryCount(category)}`,
          whitespace,
        });
        whitespace = false;
      }
    }

    return list;
  }
}
