import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule, inject, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import { Constants } from "@core/constants";
import { CoreModule } from "@core/core.module";
import { GlobalErrorHandler } from "@core/error/global-error-handler";
import { AuthService } from "@core/services/auth.service";
import { ConfigService } from "@core/services/config.service";
import { WINDOW_PROVIDERS } from "@core/services/window.service";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { WINDOW } from "@ng-web-apis/common";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxPermissionsModule } from "ngx-permissions";
import { Observable, map, switchMap } from "rxjs";

const translateHttpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(httpClient, `./assets/translations/`, ".json");
};

function initializeApp(
  authService: AuthService,
  configService: ConfigService,
  translateService: TranslateService,
): () => Observable<void> {
  const window = inject<Window>(WINDOW);

  if (window.location.href.includes("/#/")) {
    window.location.replace(window.location.href.replace("/#/", "/"));
  }

  // console.log("Initializing PlanApp..., url=", window.location.href);
  return () => {
    // Set fall back language NL
    translateService.setDefaultLang(Constants.LANGUAGE_NL);
    // Get Language from a query parameter or local storage
    const lang = determineLanguage();
    // prettier-ignore
    return translateService.use(lang)
      .pipe(
        switchMap(() => authService.login()),
        switchMap(() => configService.loadConfiguration()),
        // tap(() => console.log('PlanApp initialized, Configuration loaded.')),
        map(() => void 0),
    );
  };
}

function determineLanguage(): string {
  const translationsAvailable = [Constants.LANGUAGE_NL, Constants.LANGUAGE_EN];
  const urlParams = new URLSearchParams(window.location.search);
  const languageFromQueryParameter = urlParams.get(Constants.LANGUAGE_QUERY_PARAMETER)?.toLowerCase() ?? "";

  if (translationsAvailable.includes(languageFromQueryParameter)) {
    localStorage.setItem(Constants.LANGUAGE_KEY, languageFromQueryParameter);
  }

  return localStorage.getItem(Constants.LANGUAGE_KEY) ?? Constants.LANGUAGE_DEFAULT;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    TranslateModule.forRoot({
      defaultLanguage: Constants.LANGUAGE_NL,
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService, ConfigService, TranslateService],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
