import { Component, Input } from "@angular/core";

export type GenericDataList = {
  label: string | undefined;
  value: string | number | undefined;
  whitespace?: boolean;
  full?: boolean;
};

@Component({
  selector: "pa-generic-data-table",
  templateUrl: "./generic-data-table.component.html",
  styleUrls: ["./generic-data-table.component.scss"],
})
export class GenericDataTableComponent {
  @Input() list: GenericDataList[] | undefined;
}
