import { inject, Injectable } from "@angular/core";
import { ApiExamenTypes } from "@core/api/model/api-examen-type";
import { ExamType } from "@core/domain/examtype";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ExamTypeService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);

  getExamTypes(): Observable<ExamType[]> {
    return (
      // prettier-ignore
      this.httpGet<ApiExamenTypes>('examentype')
        .pipe(
          map(types => {
            return types?.items?.map(item => this.mappingService.toExamType(item)) ?? [];
          }),
        )
    );
  }
}
