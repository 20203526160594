import { Component, Input } from "@angular/core";
import { Constants } from "@core/constants";
import { CourseDetail } from "@core/domain/course-detail";
import { ModalController } from "@ionic/angular";
import { SelectedPeriod, CourseData } from "@shared/types/course-data";

@Component({
  selector: "pa-schedule-course",
  templateUrl: "./schedule-course-modal.component.html",
  styleUrls: ["./schedule-course-modal.component.scss"],
})
export class ScheduleCourseModalComponent {
  @Input() courseData!: CourseData;
  @Input() courseDetail!: CourseDetail;

  protected readonly Constants = Constants;

  constructor(private modalCtrl: ModalController) {}

  dismiss(): void {
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }

  selectedPeriod(selectedPeriod: SelectedPeriod): void {
    this.modalCtrl.dismiss(selectedPeriod, Constants.BUTTON_ACTION_OK);
  }

  moveToPlanLater(): void {
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_PLANLATER);
  }
}
