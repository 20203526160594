import { OnInit, Component, inject, Input } from "@angular/core";
import { AcademicYear } from "@core/domain/academic-year";
import { PlanYearService } from "@feature/schedule/services/plan-year.service";
import { InputCustomEvent, ModalController } from "@ionic/angular";

@Component({
  selector: "pa-change-year-modal",
  templateUrl: "./change-year-modal.component.html",
  styleUrls: ["./change-year-modal.component.scss"],
})
export class ChangeYearModalComponent implements OnInit {
  @Input() title!: string;
  @Input() textOK = "menuGeneral.btnChangeStartYearSubmit";
  @Input() textCancel = "label.cancel";

  selectedYear = 0;
  academicYears!: AcademicYear[];

  planYearService = inject(PlanYearService);

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    // prettier-ignore
    this.planYearService.fetchAcademicYears()
      .subscribe(years => {
        this.academicYears = years ?? [];
      });
  }

  cancel(): void {
    this.modalCtrl.dismiss(null, "cancel");
  }

  confirm(): void {
    this.modalCtrl.dismiss(this.selectedYear, "ok");
  }

  handleChange($event: Event): void {
    const changeEvent = $event as InputCustomEvent;

    if (changeEvent.detail.value) {
      this.selectedYear = Number.parseInt(changeEvent.detail.value);
    }
  }
}
