import { Injectable } from "@angular/core";
import { Constants } from "@app/core/constants";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  getLanguage(): string {
    return localStorage.getItem(Constants.LANGUAGE_KEY) ?? Constants.LANGUAGE_DEFAULT;
  }

  setLanguage(language: string): void {
    localStorage.setItem(Constants.LANGUAGE_KEY, language);
  }
}
