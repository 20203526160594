import { Injectable } from "@angular/core";
import { StatusMessage } from "@core/domain/status-message";

@Injectable({
  providedIn: "root",
})
export class StatusMessageHelper {
  constructor() {}

  isGenericStatusMessage(statusMessage: StatusMessage): boolean {
    return statusMessage.columns === undefined || statusMessage.columns.length < 2;
  }

  isPeriodStatusMessage(message: StatusMessage, year: number, period: number): boolean {
    if (message.columns.length === 2) {
      return Number.parseInt(message.columns[0]) === year && Number.parseInt(message.columns[1]) === period;
    }

    return false;
  }

  isCourseStatusMessage(message: StatusMessage, year: number, period: number, course: string): boolean {
    if (message.columns.length === 3) {
      return (
        Number.parseInt(message.columns[0]) === year &&
        Number.parseInt(message.columns[1]) === period &&
        message.columns[2] === course
      );
    }

    return false;
  }
}
