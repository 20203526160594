<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        {{ title | translate }}
      </ion-title>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <p class="ion-padding-horizontal" [innerHTML]="text | translate"></p>
  @if (info) {
    <p class="ion-padding-horizontal">{{ info | translate }}</p>
  }
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-padding-horizontal">
    @if (textCancel) {
      <ion-button class="confirm-cancel" (click)="cancel()" fill="clear" color="primary" slot="start">
        {{ textCancel | translate }}
      </ion-button>
    }
    <ion-button class="confirm-button" (click)="confirm()" fill="solid" color="success" slot="end">
      {{ textOK | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
