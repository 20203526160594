import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { GenericHttpService } from "./generic-http.service";

@Injectable({
  providedIn: "root",
})
export class VersionService extends GenericHttpService {
  public osirisVersion: string = "?";

  private async getVersion(): Promise<string> {
    try {
      const data = await firstValueFrom(this.httpClient.get<{ version: string }>("assets/osiris_version.json"));
      return data?.version || "?";
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.url?.includes("localhost")) {
        return "localhost";
      }
      console.error("Error reading the file assets/osiris_version.json:", error);
      return "unknown";
    }
  }

  setOsirisVersion(): void {
    this.getVersion().then((version) => {
      this.osirisVersion = version ?? "?";
    });
  }
}
