<ion-item lines="none" class="course-card" [ngStyle]="{ 'border-left-color': courseHelper.getCourseColor(course) }">
  <div class="course-lines">
    <div class="course-line">
      <span class="course-name">
        @if (course.code) {
          <b>{{ course.code }}</b>
        }
        {{ courseHelper.getCourseName(course) }}
      </span>
    </div>

    <div class="course-line">
      @if (course.studyPoints) {
        <span class="course-points">
          <pa-study-points [points]="course.studyPoints" />
        </span>
      }

      @if (course.categoryDescription) {
        <span class="course-category">
          {{ course.categoryDescription }}
        </span>
      }
    </div>
  </div>
</ion-item>
