import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiCollegeJaren } from "@core/api/model/api-collegejaren";
import { ApiPlanResponse } from "@core/api/model/api-plan";
import { AcademicYear } from "@core/domain/academic-year";
import { PlanResponse } from "@core/domain/plan-details";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlanYearService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);

  addYear(splaID: number): Observable<PlanResponse> {
    const addYearURL = `plan/${splaID}/toevoegen_jaar`;

    return this.httpPut<ApiPlanResponse>(addYearURL).pipe(
      map((response) => {
        return this.mappingService.toPlanResponse(response);
      }),
    );
  }

  removeYear(splaID: number): Observable<PlanResponse> {
    const removeYearURL = `plan/${splaID}/verwijder_jaar`;

    return this.httpPut<ApiPlanResponse>(removeYearURL).pipe(
      map((response) => {
        return this.mappingService.toPlanResponse(response);
      }),
    );
  }

  changeStartYear(splaID: number, confirmChangeYear: number): Observable<PlanResponse> {
    const changeYearURL = `plan/${splaID}/wijzig_start_jaar`;
    const params = new HttpParams().set("start_jaar", confirmChangeYear);

    // prettier-ignore
    return this.httpPut<ApiPlanResponse>(changeYearURL, params)
      .pipe(
        map(response => this.mappingService.toPlanResponse(response)),
      );
  }

  fetchAcademicYears(): Observable<AcademicYear[] | undefined> {
    // prettier-ignore
    return this.httpGet<ApiCollegeJaren>('collegejaren')
      .pipe(
        map(collegejaren => {
          return collegejaren
            ? collegejaren.items.map(item => this.mappingService.toAcademicYear(item))
            : undefined;
        }),
      );
  }
}
