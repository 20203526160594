<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        {{ title }}
      </ion-title>
    </ion-button>
  </ion-toolbar>
</ion-header>

@if (academicYears) {
  <ion-content class="ion-padding">
    <ion-list>
      <ion-item>
        <ion-select
          [attr.aria-label]="'ariaLabel.selectYear' | translate"
          interface="popover"
          placeholder="{{ 'menuGeneral.btnChangeStartYear' | translate }}"
          (ionChange)="handleChange($event)"
        >
          @for (academicYear of academicYears; track academicYear.year) {
            <ion-select-option [value]="academicYear.year">
              {{ academicYear.description }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
    </ion-list>
  </ion-content>
}

<ion-footer>
  <ion-toolbar class="ion-padding-horizontal">
    <ion-button class="confirm-cancel" (click)="cancel()" fill="clear" color="primary" slot="start">
      {{ textCancel | translate }}
    </ion-button>
    <ion-button
      class="confirm-button"
      (click)="confirm()"
      [disabled]="selectedYear === 0"
      fill="solid"
      color="success"
      slot="end"
    >
      {{ textOK | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
