import { Component, inject, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Constants } from "@core/constants";
import { CourseDetail } from "@core/domain/course-detail";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { CatalogSearchInfo } from "@feature/catalog/pages/catalog-page.component";
import { ModalController } from "@ionic/angular";
import { PlanActionService } from "@shared/services/plan-action.service";
import { PlanStateService } from "@shared/services/plan-state.service";
import { SelectedPeriod, CourseData } from "@shared/types/course-data";

@Component({
  selector: "pa-course-info-modal",
  templateUrl: "./course-info-modal.component.html",
  styleUrls: ["./course-info-modal.component.scss"],
})
export class CourseInfoModalComponent {
  @Input() courseData!: CourseData;
  @Input() courseDetail!: CourseDetail;
  @Input() isScheduleSelectable = false;
  @Input() viewStartedBy!: string;

  private planStateService = inject(PlanStateService);
  private planActionService = inject(PlanActionService);
  private modalCtrl = inject(ModalController);
  private courseHelper = inject(CourseHelper);
  private router = inject(Router);

  constructor() {}

  showRemove(): boolean {
    const plan = this.planStateService.currentSelectedPlan();
    return this.courseHelper.isRemoveAllowed(this.courseData, plan);
  }

  removeFromPlanning(): void {
    // prettier-ignore
    this.dismiss()
      .then(() => this.planActionService.removeCourseFromPlan(this.courseData));
  }

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, "cancel");
  }

  selectedPeriod(selectedPeriod: SelectedPeriod): void {
    this.planActionService.moveCourseFromCourseInfo(this.courseData, selectedPeriod);
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);
  }

  protected readonly Constants = Constants;

  openCatalog(event: CatalogSearchInfo): void {
    this.dismiss();
    this.router.navigate(["/catalog/minors"], {
      queryParams: { minorCode: event.code, minorName: event.name, minorDescription: event.description },
    });
  }
}
