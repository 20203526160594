import { Injectable } from "@angular/core";
import { ApiCurriculumCursus } from "@core/api/model/api-curriculum";
import { ApiCursus } from "@core/api/model/api-cursus";
import {
  ApiCursusDetail,
  ApiCursusPeriode,
  ApiOnderdeelVanMinor,
  ApiStudiejaar,
} from "@core/api/model/api-cursus-detail";
import { Course } from "@core/domain/course";
import { AcademicYear, CourseDetail, CoursePeriod, PartOfMinor } from "@core/domain/course-detail";
import { CurriculumCourse } from "@core/domain/curriculum";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { CaciSanitizer } from "@core/util/caci-sanitizer";

@Injectable({
  providedIn: "root",
})
export class CourseMappingService {
  private courseDetail!: CourseDetail;

  constructor(private caciSanitizer: CaciSanitizer) {}

  mapCourse(apiCursus: ApiCursus): Course {
    return {
      id: apiCursus.spcu_id,
      code: apiCursus.cursus,
      name: apiCursus.korte_naam,
      fullName: apiCursus.lange_naam,
      startingBlock: apiCursus.aanvangsblok,
      timeslots: ApiHelper.stringListToArray(apiCursus.timeslots),
      ownCourse: apiCursus.eigen_cursus,
      ownCoursePoints: apiCursus.eigen_cursus_punten,
      studyProgram: apiCursus.examenprogramma,
      examComponent: apiCursus.examenonderdeel,
      planLater: ApiHelper.caciBooleanToBoolean(apiCursus.plan_later),
      statusObtained: ApiHelper.caciBooleanToBooleanOrUndefined(apiCursus.status_behaald),
      statusRegistered: ApiHelper.caciBooleanToBoolean(apiCursus.status_ingeschreven),
      ribbonPeriods: ApiHelper.numberListToArray(apiCursus.lint_periodes),
      statusStudyYear: apiCursus.status_studiejaar,
      statusPeriod: apiCursus.status_periode,
      replaceOld: apiCursus.vervanging_oud,
      replaceNew: apiCursus.vervanging_nieuw,
      label: apiCursus.label,
      color: apiCursus.kleur,
      statusValidated: apiCursus.status_goedgekeurd,
      studyPoints: apiCursus.studiepunten,
      isFlexCourse: ApiHelper.caciBooleanToBoolean(apiCursus.flex_cursus),
      statusMessages: [],

      category: apiCursus.categorie,
      categoryDescription: apiCursus.categorie_oms,
      minorElsewhere: ApiHelper.caciBooleanToBoolean(apiCursus.bijvak_elders),
      minorPointsElsewhere: apiCursus.bijvak_punten_elders,
      sorting1: apiCursus.sortering_1,
      sorting2: apiCursus.sortering_2,
      sorting3: apiCursus.sortering_3,

      // Transient fields:
      lectureYear: 0,
      planId: 0,
      isRibbon: false,
      yearNr: 0, // If set, the course has been planned
      periodNr: 0,
      periodDescription: "",
      ribbonNr: 1,
    } as Course;
  }

  mapCurriculumCourse(apiCursus: ApiCurriculumCursus): CurriculumCourse {
    return {
      id: undefined as unknown as number, // does not exist for curriculum courses
      code: apiCursus.cursus,
      name: apiCursus.korte_naam,
      fullName: apiCursus.lange_naam,
      startingBlock: apiCursus.aanvangsblok,
      timeslots: ApiHelper.stringListToArray(apiCursus.timeslots),
      ownCourse: apiCursus.eigen_cursus,
      ownCoursePoints: apiCursus.eigen_cursus_punten,
      studyProgram: apiCursus.examenprogramma,
      examComponent: apiCursus.onderdeel,
      planLater: ApiHelper.caciBooleanToBoolean(apiCursus.plan_later),
      statusObtained: ApiHelper.caciBooleanToBooleanOrUndefined(apiCursus.status_behaald),
      statusRegistered: ApiHelper.caciBooleanToBoolean(apiCursus.status_ingeschreven),
      ribbonPeriods: ApiHelper.numberListToArray(apiCursus.lint_periodes),
      statusStudyYear: apiCursus.status_studiejaar,
      statusPeriod: apiCursus.status_periode,
      replaceOld: apiCursus.vervanging_oud,
      replaceNew: apiCursus.vervanging_nieuw,
      label: "", // TODO apiCursus.label ??
      color: "", // TODO apiCursus.kleur ??
      statusValidated: apiCursus.status_goedgekeurd,
      studyPoints: Number.parseInt(apiCursus.punten),
      isFlexCourse: false, // TODO ApiHelper.caciBooleanToBoolean(apiCursus.flex_cursus) ??
      statusMessages: [],

      category: "", // TODO apiCursus.categorie ??
      categoryDescription: apiCursus.categorie_omschrijving,
      minorElsewhere: false, // TODO ApiHelper.caciBooleanToBoolean(apiCursus.bijvak_elders), ??
      minorPointsElsewhere: -1, // TODO apiCursus.bijvak_punten_elders, ??
      sorting1: -1, // TODO apiCursus.sortering_1, ??
      sorting2: -1, // TODO apiCursus.sortering_2, ??
      sorting3: -1, // TODO apiCursus.sortering_3, ??

      // Transient fields:
      lectureYear: 0,
      planId: 0,
      isRibbon: false,
      yearNr: 0, // If set, the course has been planned
      periodNr: 0,
      periodDescription: "",
      ribbonNr: 1,

      // From CurriculumCourse:
      kind: apiCursus.soort,
      examType: apiCursus.examentype,
      studyProgramCourse: apiCursus.examenprogramma_cursus,
      minor: apiCursus.minor,
      examComponentId: apiCursus.id_examenonderdeel,
      examComponentName: apiCursus.onderdeel_naam,
      studyProgramComponent: apiCursus.examenprogramma_onderdeel,
      typeDescription: apiCursus.type_omschrijving,
      unit: apiCursus.punteneenheid,
      progress: apiCursus.voortgang,
      registered: ApiHelper.caciBooleanToBoolean(apiCursus.ingeschreven),
      endResult: apiCursus.eindresultaat,
      resultDescription: apiCursus.resultaat_omschrijving,
      validityPeriodExpired: ApiHelper.caciBooleanToBoolean(apiCursus.geldigheidsduur_verlopen),
      adequate: ApiHelper.caciBooleanToBoolean(apiCursus.voldoende),
      testDate: apiCursus.toetsdatum,
      order: apiCursus.volgnummer,
      partOrder1: apiCursus.onderdeel_volgnummer_1,
      partOrder2: apiCursus.onderdeel_volgnummer_2,
      orderCourse: apiCursus.cursus_volgnummer,
      examComponentSPCU: apiCursus.examenonderdeel_spcu,
    } as CurriculumCourse;
  }

  mapCourseDetail(apiCursus: ApiCursusDetail): CourseDetail {
    if (apiCursus.statusmeldingen !== undefined) {
      this.courseDetail = {} as CourseDetail;
      return this.courseDetail;
    }

    this.courseDetail = {
      course: apiCursus.cursus,
      academicYear: apiCursus.collegejaar,
      shortName: apiCursus.korte_naam,
      fullName: apiCursus.lange_naam,
      category: apiCursus.categorie,
      categoryDescription: apiCursus.categorie_oms,
      maximumPoints: apiCursus.maximum_punten,
      minimumPoints: apiCursus.minimum_punten,
      pointsUnit: apiCursus.punteneenheid,
      studyPoints: apiCursus.studiepunten,
      insiderKnowledge: apiCursus.voorkennis,
      gainInsiderKnowledge: apiCursus.voorkennis_verkrijgen,
      selfstudy: apiCursus.zelfstudie,
      contactperson: apiCursus.contactpersoon,
      contactpersonEmail: apiCursus.contactpersoon_email,
      contactpersonPhonenumber: apiCursus.contactpersoon_telefoonnummer,
      coursetype: apiCursus.cursustype,
      coursetypeDescription: apiCursus.cursustype_omschrijving,
      faculty: apiCursus.faculteit,
      facultyName: apiCursus.faculteit_naam,
      organisationalUnit: apiCursus.organisatieonderdeel,
      orgaDescription: apiCursus.orga_omschrijving,
      registerViaStudent: ApiHelper.caciBooleanToBoolean(apiCursus.inschrijven_via_student),
      sideline: ApiHelper.caciBooleanToBoolean(apiCursus.bijvakker),
      remark: apiCursus.opmerking,
      annotationIntern: apiCursus.aantekening_intern,
      showGrit: ApiHelper.caciBooleanToBoolean(apiCursus.toon_rooster),
      partOfMinor: apiCursus.onderdeel_van_minor?.map((minor) => this.toPartOfMinor(minor)),
      spcuId: apiCursus.spcu_id,
      isFlexCourse: ApiHelper.caciBooleanToBoolean(apiCursus.flex_cursus),
      urlOwc: apiCursus.url_owc,
      tutors: ApiHelper.stringListToArray(apiCursus.docenten),
      careCompontents: apiCursus.verzorgende_onderdelen,
      mainLanguages: apiCursus.voertalen,
      prerequirement: this.caciSanitizer.sanitize(apiCursus.ingangseisen),
      prerequirementNotFulfilled: this.caciSanitizer.sanitize(apiCursus.ingangseisen_niet_voldaan),
      studyYear: apiCursus.studiejaren?.map((studiejaar) => this.toAcademicYear(studiejaar)),
      content: this.caciSanitizer.sanitize(apiCursus.inhoud),
    } as CourseDetail;

    return this.courseDetail;
  }

  private toPartOfMinor(onderdeel: ApiOnderdeelVanMinor): PartOfMinor {
    return {
      studyProgram: onderdeel.examenprogramma,
      minorName: onderdeel.minor_naam,
      minor: onderdeel.minor,
    } as PartOfMinor;
  }

  private toAcademicYear(apiStudiejaar: ApiStudiejaar): AcademicYear {
    return {
      academicYear: apiStudiejaar.collegejaar,
      flexiblePlanCourse: ApiHelper.caciBooleanToBoolean(apiStudiejaar.flexibel_plannen_cursus),
      studyYear: apiStudiejaar.studiejaar,
      studyYearDescription: apiStudiejaar.collegejaar_oms,
      periods: apiStudiejaar.periodes.map((periode) => this.toPeriod(periode)),
    } as AcademicYear;
  }

  private toPeriod(apiPeriode: ApiCursusPeriode): CoursePeriod {
    return {
      current: ApiHelper.caciBooleanToBoolean(apiPeriode.actueel),
      periodNr: apiPeriode.periode_nr,
      periodDescription: apiPeriode.periode_oms,
      ribbonPeriods: ApiHelper.numberListToArray(apiPeriode.lint_periodes),
      timeslots: ApiHelper.stringListToArray(apiPeriode.timeslots),
      offered: ApiHelper.caciBooleanToBoolean(apiPeriode.aangeboden),
    } as CoursePeriod;
  }
}
