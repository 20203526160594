import { Component, computed, inject } from "@angular/core";
import { ConfirmedCourseGroup, ConfirmCourseListBuilder } from "@feature/profile/services/confirm-course-list.builder";
import { ProfileModalStateService } from "@feature/profile/services/profile-modal-state.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pa-minor-electives-courses-confirm",
  templateUrl: "./minor-electives-courses-confirm.component.html",
  styleUrls: ["./minor-electives-courses-confirm.component.scss"],
})
export class MinorElectivesCoursesConfirmComponent {
  private modalStateService = inject(ProfileModalStateService);
  private translate = inject(TranslateService);

  profile = this.modalStateService.profile;
  selectedCourseList = computed<ConfirmedCourseGroup[]>(() => {
    const profile = this.profile();
    return profile ? this.confirmCourseListBuilder.buildCourseList(profile) : [];
  });

  private confirmCourseListBuilder: ConfirmCourseListBuilder;

  constructor() {
    this.confirmCourseListBuilder = new ConfirmCourseListBuilder(this.translate, this.modalStateService);
  }
}
