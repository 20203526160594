import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiPlanHistory, ApiPlanHistoryDetails } from "@core/api/model/api-plan-history";
import { PlanHistory, PlanHistoryDetails } from "@core/domain/plan-history";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlanHistoryService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);

  fetchPlanHistory(planId: number): Observable<PlanHistory | undefined> {
    const url = `plan/${planId}/historie`;

    // prettier-ignore
    return this.httpGet<ApiPlanHistory | undefined>(url)
      .pipe(
        map(plan => plan ? this.mappingService.toPlanHistory(plan) : undefined)
      );
  }

  fetchPlanHistoryDetails(
    planId: number,
    hsplId: number,
    studentNr: string,
  ): Observable<PlanHistoryDetails | undefined> {
    // console.log(`>>> Fetching plan history details ${planId}, ${hsplId}`);
    const url = `plan/${planId}/historie/${hsplId}`;

    // prettier-ignore
    return this.httpGet<ApiPlanHistoryDetails>(url, {} as HttpParams, this.getStudentNrHeader(studentNr))
      .pipe(
        map(plan => {
          return plan ? this.mappingService.toPlanHistoryDetails(plan) : undefined;
        }),
      );
  }

  getDocument(documentId: number, studentNr: string): Observable<Blob> {
    // console.log(`>>> Fetching plan history attachment document, ${documentId}`);
    const url = `plan/documenten/${documentId}/bestand`;

    return this.httpGetBlob(url, this.getStudentNrHeader(studentNr));
  }
}
