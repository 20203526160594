import { computed, inject, Injectable, signal } from "@angular/core";
import { Constants } from "@core/constants";
import { Course } from "@core/domain/course";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PlanHelper } from "@core/domain/helpers/plan-helper";
import { Plan } from "@core/domain/plan";
import { Period, PlanDetails } from "@core/domain/plan-details";
import { PlanLater } from "@core/domain/plan-later";
import { AuthService } from "@core/services/auth.service";
import { StatusMessageService } from "@feature/plan/services/status-message.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "@shared/services/toast-service";
import { CourseData, SelectedPeriod } from "@shared/types/course-data";
import { MainPage } from "@shared/types/pages";
import { NgxPermissionsService } from "ngx-permissions";

@Injectable({
  providedIn: "root",
})
export class PlanStateService {
  private plan: Record<number, PlanDetails> = {};

  private authService = inject(AuthService);
  private planHelper = inject(PlanHelper);
  private courseHelper = inject(CourseHelper);
  private translate = inject(TranslateService);
  private toastService = inject(ToastService);
  private statusMessageService = inject(StatusMessageService);
  private permissionsService = inject(NgxPermissionsService);

  plans = signal<Plan[] | undefined>(undefined);
  private selectedPlan = signal<PlanDetails | undefined>(undefined);
  planLater = signal<PlanLater | undefined>(undefined);
  planModified = signal<boolean>(false);

  readonly pointsPlannedCalculated = computed<number>(() => {
    return this.planHelper.calculatePointsPlanned(this.selectedPlan());
  });

  readonly currentSelectedPlan = computed<PlanDetails | undefined>(() => this.selectedPlan());

  private expandedYearsByPlanId: Map<number, string[] | undefined> = new Map();
  setExpandedYears(planId: number, years: string[] | undefined): void {
    this.expandedYearsByPlanId.set(planId, years);
  }
  getExpandedYears(planId: number): string[] | undefined {
    const storedExpandedYears = this.expandedYearsByPlanId.get(planId);
    if (!storedExpandedYears) {
      return this.selectedPlan()?.studyYears.map((year) => year.yearNr.toString());
    } else {
      return storedExpandedYears;
    }
  }

  setPlanDetails(planId: number, planDetail: PlanDetails): void {
    this.plan[planId] = planDetail;
    if (planDetail) {
      this.selectedPlan.set(planDetail);
      this.switchEmployeeRole();
    }
    this.statusMessageService.setStatusMessages(planDetail?.statusMessages, MainPage.PLAN);
  }

  getPlanDetails(planId: number): PlanDetails | undefined {
    return this.plan[planId];
  }

  isPlanModified(): boolean {
    return this.planModified();
  }

  hasValidationMessages(): boolean {
    const messages = this.statusMessageService.planStatusMessages();
    return (messages && messages.length > 0) ?? false;
  }

  markPlanAsModified(modified: boolean): void {
    this.planModified.set(modified);
  }

  addCourses(year: number, period: Period, courses: Course[], lectureYear: number): void {
    const plan = this.selectedPlan();
    if (!courses || courses.length === 0) {
      return;
    }

    courses.forEach((course) => {
      this.addCourseToPlan(year, period, course, lectureYear);
    });

    // Mark the plan as modified (period is part of plan)
    this.selectedPlan.set({ ...plan } as PlanDetails);

    this.toastService.presentToast(
      this.translate.instant(courses.length > 1 ? "selectCourse.coursesArePlanned" : "selectCourse.courseIsPlanned", {
        studiejaar: year,
        periode_oms: period.periodDescription,
      }),
    );
  }

  private addCourseToPlan(year: number, period: Period, course: Course, lectureYear: number): void {
    course.yearNr = year;
    course.lectureYear = lectureYear;
    course.periodNr = period.periodNr;
    course.periodDescription = this.courseHelper.stripPeriodDescriptionNumber(period.periodDescription);

    const newCourse = {
      ...course,
      ribbonNr: 1,
      ribbonPeriods: course.ribbonPeriods ?? [],
    };

    // Re-sort courses: ribbon courses first, then by code
    period.courses = this.courseHelper.sortCourses([...period.courses, newCourse]);

    const plan = this.selectedPlan();
    if (plan && newCourse.ribbonPeriods.length > 1) {
      this.courseHelper.planRibbonCourses(plan, newCourse);
    }

    this.markPlanAsModified(true);
  }

  removeCourseFromPlan(course: Course, showToast = true): void {
    const plan = this.selectedPlan();
    // prettier-ignore
    plan?.studyYears
      .forEach(year => {
        year.periods.forEach(period => {
          period.courses = period.courses.filter(c => !this.courseHelper.isSameCourse(c, course));
        });
      });
    course.yearNr = 0;
    course.periodNr = 0;
    course.periodDescription = "";

    this.selectedPlan.set({ ...plan } as PlanDetails);

    this.markPlanAsModified(true);

    if (showToast) {
      this.toastService.presentToast(
        this.translate.instant("courseRemove.removed", {
          course: `${course.code} ${this.courseHelper.getCourseName(course)}`,
        }),
      );
    }
  }

  isPlannedCourse(course: Course): boolean {
    // prettier-ignore
    return (
      this.selectedPlan()?.studyYears
        .flatMap(year => year.periods)
        .flatMap(period => period.courses)
        .some(c => this.courseHelper.isSameCourse(c, course) && c.yearNr !== undefined) ?? false
    );
  }

  removeDraft(planId: number): void {
    if (this.plans() && this.plan[planId]) {
      this.plans.update((plannen) => {
        return plannen!.filter((plan) => plan.id !== planId);
      });
    }
  }

  addDraft(planId: number, name: string, draftPlan: PlanDetails): void {
    if (this.plans() && !this.plan[planId]) {
      const currentPlans = this.plans() || [];
      this.plans.set([
        ...currentPlans,
        {
          degreeProgram: draftPlan.degreeProgram,
          draft: true,
          lastSaved: "",
          pointsPlanned: draftPlan.pointsPlanned,
          yearCount: draftPlan.yearCount,
          yearNr: draftPlan.yearNr,
          id: planId,
          name: name,
        },
      ]);
    }
  }

  public isReadOnlyPlanning(): boolean {
    const plan = this.selectedPlan();

    return (
      plan === undefined ||
      (this.authService.isRoleEmployee() && plan.studentNr !== "") ||
      (this.authService.isRoleStudent() && this.planHelper.hasReadOnlyStatus(plan))
    );
  }

  addCourseToPeriod(courseData: CourseData, selectedPeriod: SelectedPeriod): void {
    const plan = this.selectedPlan();
    // prettier-ignore
    const period = plan
      ?.studyYears.find(year => year.yearNr === selectedPeriod.year.studyYear)
      ?.periods.find(yearPeriod => yearPeriod.periodNr === selectedPeriod.period.periodNr);

    if (selectedPeriod.period.timeslots?.length > 0) {
      courseData.timeslots = selectedPeriod.period.timeslots;
    }

    if (period) {
      this.addCourseToPlan(selectedPeriod.year.studyYear, period, courseData, selectedPeriod.year.academicYear);
      this.selectedPlan.set({ ...plan } as PlanDetails);
    }
  }

  renameDraft(planId: number, name: string): void {
    if (this.plans() && this.plan[planId]) {
      this.plan[planId].name = name;
      const renameDraft = this.plans()?.find((plan) => plan.id === planId);
      if (renameDraft && renameDraft.name !== name) {
        renameDraft.name = name;
        this.plans.set(this.plans());
      }
    }
  }

  private switchEmployeeRole(): void {
    const plan = this.selectedPlan();

    if (plan) {
      if (plan.studentNr !== "" && this.authService.isRoleEmployee()) {
        this.addStudentPlanToEmployee(plan);
        this.permissionsService.loadPermissions([Constants.ROLE_READONLY_EMPLOYEE]);
        return;
      }

      if (plan.studentNr === "" && this.authService.isRoleReadOnlyEmployee()) {
        this.permissionsService.loadPermissions([Constants.ROLE_EMPLOYEE]);
      }
    }
  }

  private addStudentPlanToEmployee(plan: PlanDetails): void {
    if (this.plans() && !this.plans()?.find((p) => p.id === plan.id)) {
      const currentPlans = this.plans() || [];
      this.plans.set([...currentPlans, { ...plan }]);
    }
  }
}
