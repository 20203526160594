// eslint-disable-next-line no-shadow
export enum PlanLaterType {
  MANDATORY = "mandatory",
  MANDATORY_CHOICE = "mandatoryChoice",
  SPECIALISATION = "specialisation",
  PROFILE_MINOR = "profileMinor",
  PROFILE_COURSES = "profileCourses",
  OTHER_COURSES = "otherCourses",
  REPLACEMENT_COURSES = "replacementCourses",
}
