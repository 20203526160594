<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        {{ title }}
      </ion-title>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="new-draft-container">
    <ion-input
      #draftName
      label="{{ 'draftMenu.name' | translate }}"
      labelPlacement="floating"
      class="new-draft-name"
      [(ngModel)]="name"
      required
      [maxlength]="Constants.MAX_LENGTH_PLAN_NAME"
      counter
      [attr.aria-label]="'ariaLabel.changeDraftName' | translate"
    />
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-padding-horizontal">
    <ion-button class="confirm-cancel" (click)="dismiss()" fill="clear" color="primary" slot="start">
      {{ textCancel | translate }}
    </ion-button>
    <ion-button
      class="confirm-button"
      (click)="confirm()"
      [disabled]="name.trim() === ''"
      fill="solid"
      color="success"
      slot="end"
    >
      {{ textOK | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
