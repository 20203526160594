import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Constants } from "@core/constants";

@Component({
  selector: "pa-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent {
  @Input() title!: string;
  @Input() text!: string;
  @Input() info?: string;
  @Input() textOK = "label.ok";
  @Input() textCancel = "label.cancel";

  constructor(private modalCtrl: ModalController) {}

  cancel(): void {
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }

  confirm(): void {
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);
  }
}
