<ion-toolbar color="light">
  <ion-button fill="clear" slot="start">
    <ion-title>
      {{ "label.planNow" | translate }}
    </ion-title>
  </ion-button>
  <ion-button fill="clear" (click)="dismiss()" slot="end" [attr.aria-label]="'ariaLabel.closeButton' | translate">
    <ion-icon icon="close"></ion-icon>
  </ion-button>
</ion-toolbar>

<div class="pa-plan-element">
  @if (courseDetail) {
    <pa-plan-element [courseDetail]="courseDetail" isScheduleSelectable (selectedPeriod)="selectedPeriod($event)" />
  }
</div>

<ng-template [ngxPermissionsExcept]="Constants.ROLE_READONLY_EMPLOYEE">
  @if (courseData.isMovableToPlanLater) {
    <ion-footer>
      <ion-toolbar class="ion-padding-horizontal">
        <ion-button class="confirm-button" (click)="moveToPlanLater()" fill="clear" color="primary" slot="start">
          {{ "schedule.toPlanLater" | translate }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  }
</ng-template>
