import { AfterViewChecked, Component, Input, ViewChild } from "@angular/core";
import { Constants } from "@core/constants";
import { IonInput, ModalController } from "@ionic/angular";

@Component({
  selector: "pa-rename-copy-draft-modal",
  templateUrl: "./rename-copy-draft-modal.component.html",
  styleUrls: ["./rename-copy-draft-modal.component.scss"],
})
export class RenameCopyDraftModalComponent implements AfterViewChecked {
  @Input() title!: string;
  @Input() textOK!: string;
  @Input() textCancel = "label.cancel";

  @ViewChild("draftName") draftName!: IonInput;

  protected readonly Constants = Constants;

  name = "";
  constructor(private modalCtrl: ModalController) {}

  ngAfterViewChecked(): void {
    this.draftName.setFocus();
  }

  confirm(): void {
    this.modalCtrl.dismiss(this.name, Constants.BUTTON_ACTION_OK);
  }

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }
}
