import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiUser } from "@core/api/model/api-user";
import { Constants } from "@core/constants";
import { User } from "@core/domain/user";
import { GenericHttpService } from "@core/services/generic-http.service";
import { environment as env } from "@environment/environment";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, map, Observable, of, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService extends GenericHttpService {
  private loggedIn = false;
  private userSubject: BehaviorSubject<User | undefined>;
  private userName!: string;
  private permissionsService = inject(NgxPermissionsService);

  constructor() {
    super();

    this.userSubject = new BehaviorSubject<User | undefined>(undefined);
  }

  getOrFetchUser(): Observable<User> {
    const cachedUser = this.userSubject.getValue();

    if (cachedUser) {
      return of(cachedUser);
    }

    // prettier-ignore
    return this.httpGet<ApiUser>('user')
      .pipe(
        // According to the API spec, the user object cannot be undefined here
        map(apiUser => this.handleUserResponse(apiUser!)),
      );
  }

  getUser$(): Observable<User | undefined> {
    return this.userSubject.asObservable();
  }

  login(): Observable<void> {
    this.extractAccessTokenFromUrl();

    // prettier-ignore
    const params = new HttpParams()
      .set('authentication_type', env.authentication_type ?? 'student');

    this.extractPlanIdFromUrl();

    return (
      // prettier-ignore
      this.httpGet<void>('authenticeer', params)
        .pipe(
          tap(() => {
            this.loggedIn = true;
          }),
        )
    );
  }

  extractPlanIdFromUrl(): void {
    // Extact planId from /bekijkplanning/id=xxx in the URL and store it in local storage.

    const urlPath = this.window.location.pathname || "/";
    const searchStr = "/bekijkplanning/id=";
    const searchPos = urlPath.indexOf(searchStr);
    const isNavigateFromDocent = searchPos !== -1;
    const planId = isNavigateFromDocent ? urlPath.substring(searchPos + searchStr.length) : null;
    if (isNavigateFromDocent) {
      this.saveStudentPlanId(planId);
    }
  }

  removeStudentPlanId(): void {
    localStorage.removeItem(Constants.STORAGE_STUDENT_PLAN_ID);
  }

  saveStudentPlanId(planId: string | null): void {
    if (!planId) {
      this.removeStudentPlanId();
    } else {
      const planDigits = planId.replace(/(^\d+)(.*$)/i, "$1");
      localStorage.setItem(Constants.STORAGE_STUDENT_PLAN_ID, planDigits);
    }
  }

  logout(): void {
    this.removeStudentPlanId();
    // prettier-ignore
    this.httpGet<string>('uitloggen')
      .subscribe(res => {
        const redirectUrl = this.extractRedirectUrlFromResponse(res);
        this.loggedIn = false;
        this.userName = '';
        this.userSubject.next(undefined);

        this.saveAccessToken(null, null, null);
        // console.log('Logout redirect to:', redirectUrl ?? '/session/login');

        // Redirecting to a page outside this application.
        this.window.location.href = redirectUrl ?? '/session/login';
      });
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  isRoleEmployee(): boolean {
    return Constants.ROLE_EMPLOYEE === this.permissionsService.getPermission(Constants.ROLE_EMPLOYEE)?.name;
  }

  isRoleStudent(): boolean {
    return Constants.ROLE_STUDENT === this.permissionsService.getPermission(Constants.ROLE_STUDENT)?.name;
  }

  isRoleReadOnlyEmployee(): boolean {
    return (
      Constants.ROLE_READONLY_EMPLOYEE === this.permissionsService.getPermission(Constants.ROLE_READONLY_EMPLOYEE)?.name
    );
  }

  extractAccessTokenFromUrl(): void {
    // If we were redirected to the application after logging in, the access token is provided in the URL.
    // Store it for future calls.

    const params = new URLSearchParams(this.window.location.href.split("#")[1]);
    const token = params.get("access_token");
    if (token) {
      this.saveAccessToken(token, params.get("token_type"), params.get("expires_in"));
    }
  }

  getUser(): string {
    return this.userName;
  }

  private handleUserResponse(apiUser: ApiUser): User {
    const nameParts = [
      apiUser.roepnaam?.trim() || apiUser.voorletters?.trim(), // Use roepnaam if available, otherwise use voorletters
      apiUser.voorvoegsels?.trim(),
      apiUser.achternaam.trim(),
    ]
      .filter((part) => part)
      .join(" ");

    const user = {
      studentNr: apiUser.studentnummer,
      name: nameParts || "",
      roles: apiUser.roles,
    } as User;

    this.userName = user.name;
    this.userSubject.next(user);

    switch (true) {
      case user.roles.includes(Constants.ROLE_STUDENT):
        this.permissionsService.loadPermissions([Constants.ROLE_STUDENT]);
        break;
      case user.roles.includes(Constants.ROLE_EMPLOYEE):
        this.permissionsService.loadPermissions([Constants.ROLE_EMPLOYEE]);
        break;
      default:
        console.error("Not supported user role", user.roles);
    }

    if (user.roles.includes(Constants.ROLE_STUDENT)) {
      // /bekijkplanning/id= is not for students (employee logt in as student?)
      this.removeStudentPlanId();
    }

    return user;
  }
}
