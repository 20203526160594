<ng-container>
  <ion-button id="history-button" fill="clear" (click)="historyItemsOpen = true" color="success">
    <ion-icon icon="chevron-down-outline" slot="end" />
    <b>{{ getHistoryItemName(selectedItem) }}</b>
  </ion-button>

  <ion-popover trigger="history-button" [isOpen]="historyItemsOpen">
    <ng-template>
      <ion-content>
        <ion-list tabindex="0">
          @for (item of history.items; track $index) {
            <ion-item [button]="true" [detail]="false" (click)="selectItem(item)">
              {{ getHistoryItemName(item) }}
            </ion-item>
          }
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>

  <div class="details-panel">
    @if (selectedItem && historyDetails) {
      <pa-history-details [historyItem]="selectedItem" [historyDetails]="historyDetails" class="history-details-left" />
      <pa-history-courses
        [historyItem]="selectedItem"
        [historyDetails]="historyDetails"
        class="history-details-right"
      />
    }
  </div>
</ng-container>
