import { Component, inject, Input, OnInit } from "@angular/core";
import { Constants } from "@core/constants";
import { AcademicYear, CourseDetail, CoursePeriod } from "@core/domain/course-detail";
import { ModalController } from "@ionic/angular";
import { CourseData, SelectedPeriod } from "@shared/types/course-data";

@Component({
  selector: "pa-flexible-plan-element",
  templateUrl: "./flexible-plan-element-modal.component.html",
  styleUrls: ["./flexible-plan-element-modal.component.scss"],
})
export class FlexiblePlanElementModalComponent implements OnInit {
  @Input() courseData!: CourseData;
  @Input() courseDetail!: CourseDetail;

  academicYear = 0;
  selectedYear!: AcademicYear | undefined;
  studyYearCoursePeriods!: CoursePeriod[] | undefined;
  selectedPeriods: CoursePeriod[] = [];
  checkedSelectedPeriods: boolean[] = [];

  protected readonly Constants = Constants;

  private modalCtrl = inject(ModalController);

  constructor() {}

  ngOnInit(): void {
    document.documentElement.style.setProperty(
      "--element-period-columns",
      this.courseDetail.studyYear[0].periods.length.toString(),
    );
  }

  dismiss(): void {
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }

  clickOnPeriod(year: AcademicYear | undefined, period: CoursePeriod): void {
    if (year) {
      if (!year.flexiblePlanCourse) {
        const selectedPeriod = { year: year, period: period } as SelectedPeriod;
        this.modalCtrl.dismiss(selectedPeriod, Constants.BUTTON_ACTION_OK);
        return;
      }

      // Flexible plan course Mode
      const indexSelectedPeriod = this.selectedPeriods.findIndex(
        (coursePeriod) => coursePeriod.periodNr === period.periodNr,
      );
      if (indexSelectedPeriod >= 0) {
        this.selectedPeriods.splice(indexSelectedPeriod, 1);
      } else {
        this.selectedPeriods.push(period);
      }
      this.checkedSelectedPeriods[period.periodNr] = !this.checkedSelectedPeriods[period.periodNr];
    }
  }

  startYearChanged(academicYear: number): void {
    // prettier-ignore
    this.selectedYear = this.courseDetail.studyYear
      .find(year => year.academicYear === academicYear);
    this.studyYearCoursePeriods = this.selectedYear?.periods;
  }

  clickOnBtnOk(): void {
    // prettier-ignore
    this.selectedPeriods
      .sort((period1, period2) => period1.periodNr - period2.periodNr);

    // prettier-ignore
    this.selectedPeriods[0].ribbonPeriods = this.selectedPeriods
      .map(period => period.periodNr);

    if (this.studyYearCoursePeriods) {
      // time slot first period
      this.selectedPeriods[0].timeslots = this.studyYearCoursePeriods[0].timeslots;
    }

    const selectedPeriod = { year: this.selectedYear, period: this.selectedPeriods[0] } as SelectedPeriod;
    this.modalCtrl.dismiss(selectedPeriod, Constants.BUTTON_ACTION_OK);
  }

  moveToPlanLater(): void {
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_PLANLATER);
  }
}
