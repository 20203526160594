import { OnInit, Component, effect, inject } from "@angular/core";
import { Constants } from "@core/constants";
import { PlanProfile } from "@core/domain/plan-profile";
import { PlanService } from "@core/services/plan.service";
import { ProfileModalStateService, ProfileModalStep } from "@feature/profile/services/profile-modal-state.service";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pa-submit-plan-modal",
  templateUrl: "./submit-plan-modal.component.html",
  styleUrls: ["./submit-plan-modal.component.scss"],
})
export class SubmitPlanModalComponent implements OnInit {
  private planService = inject(PlanService);
  private profileModalStateService = inject(ProfileModalStateService);
  private modalCtrl = inject(ModalController);
  private translate = inject(TranslateService);

  readonly Constants = Constants;
  readonly ProfileModalStep = ProfileModalStep;

  profile: PlanProfile | undefined = undefined;
  selectedPoints = 0;

  currentStep: ProfileModalStep = ProfileModalStep.Choice;
  title = "";

  constructor() {
    effect(() => {
      this.profile = this.profileModalStateService.profile();
      this.selectedPoints = this.profileModalStateService.selectedPoints();
      this.determineTitle();
    });
  }

  ngOnInit(): void {
    this.determineTitle();
  }

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }

  backPressed(): void {
    this.currentStep = ProfileModalStep.Choice;
    this.determineTitle();
  }

  isSubmitButtonDisabled(): boolean {
    return this.profileModalStateService.submitDisabled;
  }

  submitPressed(): void {
    // prettier-ignore
    this.profileModalStateService.submitApproval()
      .subscribe(result => {
        if (result) {
          this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);

          // Reload the submitted plan when submit is successful
          if (this.profile) {
            this.planService.getOrFetchPlanDetails(this.profile.id, true).subscribe();
          }
        }
      });
  }

  isNextButtonDisabled(): boolean {
    return this.selectedPoints < this.profile!.minimumPoints ?? false;
  }

  nextPressed(): void {
    this.currentStep = ProfileModalStep.Confirm;
    this.determineTitle();
    this.profileModalStateService.nextStep(this.currentStep);
  }

  private determineTitle(): void {
    this.title =
      this.currentStep === ProfileModalStep.Confirm
        ? this.translate.instant("choiceMinorOrElectives.confirmTitle")
        : this.translate.instant("choiceMinorOrElectives.title");
  }
}
