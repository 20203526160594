import { Component, Input } from "@angular/core";
import { Constants } from "@core/constants";
import { ProfileModalStep } from "@feature/profile/services/profile-modal-state.service";

@Component({
  selector: "pa-minor-electives-detail",
  templateUrl: "./minor-electives-detail.component.html",
  styleUrls: ["./minor-electives-detail.component.scss"],
})
export class MinorElectivesDetailComponent {
  @Input() step!: ProfileModalStep;

  protected readonly Constants = Constants;
  protected readonly ProfileModalStep = ProfileModalStep;
}
