<div class="minor-electives-courses">
  @switch (step) {
    @case (ProfileModalStep.Choice) {
      <pa-minor-electives-courses-choice />
    }
    @case (ProfileModalStep.Confirm) {
      <pa-minor-electives-courses-confirm />
    }
    @default {
      <p>Oops...</p>
    }
  }
</div>
