import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Observable, from, of, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private loading: HTMLIonLoadingElement | undefined = undefined;

  constructor(
    private loadingCtrl: LoadingController,
    private translateService: TranslateService,
  ) {}

  present(message: string = "label.loading"): Observable<void> {
    const msg = this.translateService.instant(message);
    if (this.loading) {
      // Loader is already showing, update the message
      this.loading.message = msg;
      return of(void 0);
    } else {
      // prettier-ignore
      return from(this.loadingCtrl.create({message: msg}))
        .pipe(
          switchMap(loading => {
            this.loading = loading;
            return from(this.loading.present());
          }),
        );
    }
  }

  dismiss(): void {
    if (this.loading) {
      this.loading.dismiss().then(() => {
        this.loading = undefined;
      });
    }
  }
}
