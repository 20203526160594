import { Injectable } from "@angular/core";
import { ApiConfig, ApiConfigItem } from "@core/api/model/api-config";
import { ConfigItem } from "@core/domain/config";
import { GenericHttpService } from "@core/services/generic-http.service";
import { map, Observable, ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConfigService extends GenericHttpService {
  private configParams: ConfigItem[] | undefined;
  private configParamsSubject = new ReplaySubject<ConfigItem[] | undefined>(1);

  loadConfiguration(): Observable<ConfigItem[]> {
    // prettier-ignore
    return this.httpGet<ApiConfig>('configs')
      .pipe(
        map(config => this.handleConfigResponse(config)),
      );
  }

  getConfigParams$(): Observable<ConfigItem[] | undefined> {
    return this.configParamsSubject.asObservable();
  }

  getConfigParam(key: string): string {
    if (!this.configParams) {
      throw new Error("Configuration not loaded");
    }

    const param = this.configParams.find((config) => config.parameter === key);
    if (!param) {
      throw new Error(`Configuration parameter ${key} not found`);
    }

    return param.value;
  }

  private toConfigItem(config: ApiConfigItem): ConfigItem {
    return {
      parameter: config.parameter,
      value: config.waarde,
    } as ConfigItem;
  }

  private handleConfigResponse(config: ApiConfig | undefined): ConfigItem[] {
    if (config) {
      this.configParams = config.items.map((item) => this.toConfigItem(item));
      // console.log("Configuration loaded", config);
    } else {
      this.configParams = [];
      console.warn("Configuration NOT loaded", config);
    }

    this.configParamsSubject.next(this.configParams);
    return this.configParams;
  }
}
