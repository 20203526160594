import { Component, Input } from "@angular/core";

@Component({
  selector: "pa-add-course-list-header",
  templateUrl: "./add-course-list-header.component.html",
  styleUrls: ["./add-course-list-header.component.scss"],
})
export class AddCourseListHeaderComponent {
  @Input() title!: string;
  @Input() subTitle: string | undefined;

  constructor() {}
}
