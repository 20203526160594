export class Constants {
  static readonly ANIMATION_DURATION = 500;
  static readonly TOAST_DURATION = 4000;

  static readonly LANGUAGE_KEY = "lang";
  static readonly LANGUAGE_NL = "nl";
  static readonly LANGUAGE_EN = "en";
  static readonly LANGUAGE_DEFAULT = Constants.LANGUAGE_NL;
  static readonly LANGUAGE_QUERY_PARAMETER = "taal";

  static readonly BUTTON_ACTION_OK = "ok";
  static readonly BUTTON_ACTION_CANCEL = "cancel";
  static readonly BUTTON_ACTION_PLANLATER = "planlater";

  static readonly PLAN_STATUS_SUBMITTED_FOR_APPROVAL = "I";
  static readonly PLAN_STATUS_RESUBMIT = "O";
  static readonly PLAN_STATUS_APPROVED = "G";
  static readonly PLAN_STATUS_REJECTED = "A";
  static readonly PLAN_STATUS_ACHIEVED = "J";
  static readonly PLAN_STATUS_ADVICE_POSITIVE = "P";

  static readonly PLANNING_STATE_PLANNED = "G";
  static readonly PLANNING_STATE_REGISTERED = "I";

  static readonly REVIEW_MOMENT_FINAL = "E";
  static readonly REVIEW_MOMENT_INTERIM = "T";

  static readonly OPTION_YES = "J";
  static readonly OPTION_NO = "N";
  static readonly OPTION_MANDATORY = "V";
  static readonly OPTION_OPTIONAL = "O";

  static readonly REQUIREMENT_ONE = "EEN";
  static readonly REQUIREMENT_ALL = "ALLE";

  static readonly SEPARATOR_COMMA = ",";

  static readonly EXAM_COMPONENT_TYPE_MANDATORY = "V";
  static readonly EXAM_COMPONENT_TYPE_MANDATORY_CHOICE = "VK";
  static readonly EXAM_COMPONENT_TYPE_CHOICE = "K";

  static readonly EXAM_COMPONENT_TYPE_MANDATORY_CHOICE_CONDITION = "V"; // exam component type is mandatory choice, and course list is based on a condition (free field with certain value)

  static readonly REMARK_MAX_LENGTH = 500;

  static readonly SEARCH_DEBOUNCE_TIME_MS = 500;

  static readonly STORAGE_STUDENT_PLAN_ID = "studentPlanId";

  static readonly VIEW_STARTED_BY_PLAN = "PLAN";
  static readonly VIEW_STARTED_BY_PLANLATER = "PLAN_LATER";
  static readonly VIEW_STARTED_BY_CATALOG = "CATALOG";
  static readonly VIEW_STARTED_BY_CURRICULUM = "CURRICULUM";

  static readonly COURSEDATAGROUP_TYPE_MINOR = "MINOR";
  static readonly COURSEDATAGROUP_TYPE_SPECIALISATION = "SPECIALISATION";
  static readonly ROLE_STUDENT = "student";
  static readonly ROLE_EMPLOYEE = "employee";
  static readonly ROLE_READONLY_EMPLOYEE = "readonlyEmployee";

  static readonly MESSAGE_TYPE_ERROR = "E";
  static readonly MESSAGE_TYPE_WARNING = "W";

  static readonly MAX_LENGTH_COURSE_NAME = 40;
  static readonly MAX_LENGTH_PLAN_NAME = 35;

  static readonly MIN_STUDY_POINTS = 0;
  static readonly MAX_STUDY_POINTS = 15;
}
