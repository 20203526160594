import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class CaciSanitizer {
  constructor(private sanitizer: DomSanitizer) {}

  sanitize(sanitizeMe: string | null): string | null {
    return this.sanitizer.sanitize(SecurityContext.HTML, sanitizeMe);
  }
}
