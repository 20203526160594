<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        <b>{{ "schedule.textFlexPlannen" | translate }}</b>
      </ion-title>
    </ion-button>
    <ion-button fill="clear" (click)="dismiss()" slot="end" [attr.aria-label]="'ariaLabel.closeButton' | translate">
      <ion-icon icon="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  @if (courseDetail) {
    <div class="flexible-plan-main-container ion-padding">
      <div class="flexible-plan-with-possible-timeslot ion-padding-horizontal">
        <ion-select
          [(ngModel)]="academicYear"
          interface="popover"
          placeholder="{{ 'schedule.selectYearFlex' | translate }}"
          (ionChange)="startYearChanged(academicYear)"
        >
          @for (studyYear of courseDetail.studyYear; track studyYear.academicYear) {
            <ion-select-option [value]="studyYear.academicYear">
              {{ "schedule.labelAcademicYear" | translate }} {{ studyYear.studyYear }} ({{
                studyYear.studyYearDescription
              }})
            </ion-select-option>
          }
        </ion-select>

        @if (studyYearCoursePeriods && studyYearCoursePeriods[0].timeslots.length > 0) {
          <div class="timeslots">
            @for (timeslot of studyYearCoursePeriods[0].timeslots; track timeslot) {
              <pa-timeslot class="timeslot-spacing" [timeslot]="timeslot" />
            }
          </div>
        }
      </div>

      <div class="flexible-plan-container">
        <!--Columns for period per study year -->
        @for (period of studyYearCoursePeriods; track period.periodNr) {
          <div class="pa-plan-header-period">{{ period.periodDescription }}</div>
        }

        @for (period of studyYearCoursePeriods; track period.periodNr) {
          <div class="pa-plan-grid pa-plan-grid-hoverable" (click)="clickOnPeriod(selectedYear, period)">
            @if (!selectedYear?.flexiblePlanCourse) {
              <div class="timeslots">
                @if (period.timeslots.length > 0) {
                  @for (timeslot of period.timeslots; track $index) {
                    <pa-timeslot class="timeslot" [timeslot]="timeslot" />
                  }
                } @else {
                  <pa-timeslot class="timeslot timeslot-empty" />
                }
              </div>
            } @else {
              <div class="timeslots">
                <ion-checkbox
                  [checked]="checkedSelectedPeriods[period.periodNr]"
                  class="timeslot-empty timeslot-checkbox ion-focusable"
                />
              </div>
            }
          </div>
        }
      </div>
    </div>
  }
</ion-content>

@if (selectedYear?.flexiblePlanCourse) {
  <ion-footer>
    <ion-toolbar class="ion-padding-horizontal">
      <ion-button
        class="confirm-button"
        (click)="clickOnBtnOk()"
        [disabled]="selectedPeriods.length === 0"
        fill="solid"
        color="success"
        slot="end"
      >
        {{ "label.ok" | translate }}
      </ion-button>
    </ion-toolbar>
  </ion-footer>
}

<ng-template [ngxPermissionsExcept]="Constants.ROLE_READONLY_EMPLOYEE">
  @if (courseData.isMovableToPlanLater) {
    <ion-footer>
      <ion-toolbar class="ion-padding-horizontal">
        <ion-button class="confirm-button" (click)="moveToPlanLater()" fill="clear" color="primary" slot="start">
          {{ "schedule.toPlanLater" | translate }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  }
</ng-template>
