import { Component, Input } from "@angular/core";
import { PlanHistoryDetails, PlanHistoryItem } from "@core/domain/plan-history";

@Component({
  selector: "pa-history-courses",
  templateUrl: "./history-courses.component.html",
  styleUrls: ["./history-courses.component.scss"],
})
export class HistoryCoursesComponent {
  @Input() historyItem!: PlanHistoryItem;
  @Input() historyDetails!: PlanHistoryDetails;

  constructor() {}
}
