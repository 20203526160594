<ng-container>
  @if (dataList(); as dataList) {
    <div class="ion-margin-top">
      <pa-generic-data-table [list]="dataList" />
    </div>
  }
  @if (warnings().length > 0) {
    <hr class="warning-divider" />
    <div>
      @for (warning of warnings(); track $index) {
        <div class="warning">
          <pa-status-message [melding]="warning" />
        </div>
      }
    </div>
  }
</ng-container>
