import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { Constants } from "@core/constants";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async presentToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message: message,
      duration: Constants.TOAST_DURATION,
      position: "bottom",
    });

    await toast.present();
  }
}
