import { OnInit, Component, inject, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pa-study-points",
  templateUrl: "./study-points.component.html",
  styleUrls: ["./study-points.component.scss"],
})
export class StudyPointsComponent implements OnInit {
  @Input() points!: number | undefined;

  private translateService = inject(TranslateService);
  protected pointsAriaLabel = "";

  ngOnInit(): void {
    this.pointsAriaLabel = `${this.translateService.instant("courseDetail.studyPoints")} ${this.points}`;
  }
}
