import { OnInit, Component, Input } from "@angular/core";
import { Constants } from "@core/constants";
import { StatusMessage } from "@core/domain/status-message";

@Component({
  selector: "pa-status-message",
  templateUrl: "./status-message.component.html",
  styleUrls: ["./status-message.component.scss"],
})
export class StatusMessageComponent implements OnInit {
  @Input() melding: StatusMessage | undefined;
  icon?: string;
  examTypeDescription?: string;

  constructor() {}

  ngOnInit(): void {
    this.icon = this.melding?.type === Constants.MESSAGE_TYPE_ERROR ? "remove-circle" : "alert-circle-outline";
    this.examTypeDescription = this.getExamTypeDescription();
  }

  getExamTypeDescription(): string {
    const columnsFull = this.melding?.columns?.join(Constants.SEPARATOR_COMMA) ?? "";
    return columnsFull.indexOf("EXAMENTYPE") === 0 ? columnsFull.split(": ")[1] : "";
  }
}
