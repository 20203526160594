import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectPlanComponent } from "@feature/plan/components/select-plan/select-plan.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { AddCourseListHeaderComponent } from "@shared/components/add-course-list-header/add-course-list-header.component";
import { AddCourseListItemComponent } from "@shared/components/add-course-list-item/add-course-list-item.component";
import { AddCustomCourseComponent } from "@shared/components/add-custom-course/add-custom-course.component";
import { CollapsableTextComponent } from "@shared/components/collapseable-text/collapsable-text.component";
import { CourseActionButtonComponent } from "@shared/components/course-action-button/course-action-button.component";
import { CourseActionMenuComponent } from "@shared/components/course-action-menu/course-action-menu.component";
import { CourseDataGroupComponent } from "@shared/components/course-data-group/course-data-group.component";
import { CourseDetailComponent } from "@shared/components/course-detail/course-detail.component";
import { PlanElementComponent } from "@shared/components/course-detail/plan-element/plan-element.component";
import { CourseGroupActionMenuComponent } from "@shared/components/course-group-action-menu/course-group-action-menu.component";
import { CourseItemComponent } from "@shared/components/course-item/course-item.component";
import { DownloadFileComponent } from "@shared/components/download-file/download-file.component";
import { GenericDataTableComponent } from "@shared/components/generic-data-table/generic-data-table.component";
import { JsonPrettyPrintComponent } from "@shared/components/json-prettyprint/json-pretty-print.component";
import { LanguageSelectorComponent } from "@shared/components/language-selector/language-selector.component";
import { MainMenuToolbarComponent } from "@shared/components/main-menu-toolbar/main-menu-toolbar.component";
import { MessagesAlertComponent } from "@shared/components/messages-alert.component";
import { MinorElectivesCoursesChoiceComponent } from "@shared/components/minor-electives-courses/minor-electives-courses-choice/minor-electives-courses-choice.component";
import { MinorElectivesCoursesConfirmComponent } from "@shared/components/minor-electives-courses/minor-electives-courses-confirm/minor-electives-courses-confirm.component";
import { MinorElectivesChoiceStepComponent } from "@shared/components/minor-electives-state/minor-electives-state-choice/minor-electives-choice-step.component";
import { MinorElectivesConfirmStepComponent } from "@shared/components/minor-electives-state/minor-electives-state-confirm/minor-electives-confirm-step.component";
import { StatusMessageComponent } from "@shared/components/status-message.component";
import { StudyPointsComponent } from "@shared/components/study-points/study-points.component";
import { TimeslotComponent } from "@shared/components/timeslot/timeslot.component";
import { TooltipLabelComponent } from "@shared/components/tooltip-label/tooltip-label.component";
import { ChangeYearModalComponent } from "@shared/modals/change-year-modal/change-year-modal.component";
import { ConfirmModalComponent } from "@shared/modals/confirm-modal/confirm-modal.component";
import { CourseAddModalComponent } from "@shared/modals/course-add-modal/course-add-modal.component";
import { CourseInfoModalComponent } from "@shared/modals/course-info-modal/course-info-modal.component";
import { FlexiblePlanElementModalComponent } from "@shared/modals/flexible-plan-element-modal/flexible-plan-element-modal.component";
import { NewDraftModalComponent } from "@shared/modals/new-draft-modal/new-draft-modal.component";
import { RenameCopyDraftModalComponent } from "@shared/modals/rename-copy-draft-modal/rename-copy-draft-modal.component";
import { ScheduleCourseModalComponent } from "@shared/modals/schedule-course-modal/schedule-course-modal.component";
import { SearchableSelectComponent } from "@shared/modals/searchable-select/searchable-select.component";
import { HistoryCourseItemComponent } from "@shared/modals/show-history-modal/history-course-item/history-course-item.component";
import { HistoryCoursesComponent } from "@shared/modals/show-history-modal/history-courses/history-courses.component";
import { HistoryDetailsComponent } from "@shared/modals/show-history-modal/history-details/history-details.component";
import { HistoryInfoComponent } from "@shared/modals/show-history-modal/history-item/history-info.component";
import { ShowHistoryModalComponent } from "@shared/modals/show-history-modal/show-history-modal.component";
import { ChoiceMinorElectivesComponent } from "@shared/modals/submit-plan-modal/choice-minor-electives/choice-minor-electives.component";
import { MinorElectivesCoursesComponent } from "@shared/modals/submit-plan-modal/minor-electives-courses/minor-electives-courses.component";
import { MinorElectivesDetailComponent } from "@shared/modals/submit-plan-modal/minor-electives-detail/minor-electives-detail.component";
import { SubmitPlanModalComponent } from "@shared/modals/submit-plan-modal/submit-plan-modal.component";
import { NgxPermissionsModule } from "ngx-permissions";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, ReactiveFormsModule, NgxPermissionsModule],
  declarations: [
    AddCourseListHeaderComponent,
    AddCourseListItemComponent,
    AddCustomCourseComponent,
    ChangeYearModalComponent,
    ChoiceMinorElectivesComponent,
    CollapsableTextComponent,
    ConfirmModalComponent,
    CourseActionButtonComponent,
    CourseActionMenuComponent,
    CourseGroupActionMenuComponent,
    CourseAddModalComponent,
    CourseDataGroupComponent,
    CourseDetailComponent,
    CourseInfoModalComponent,
    CourseItemComponent,
    DownloadFileComponent,
    FlexiblePlanElementModalComponent,
    GenericDataTableComponent,
    HistoryCourseItemComponent,
    HistoryCoursesComponent,
    HistoryDetailsComponent,
    HistoryInfoComponent,
    JsonPrettyPrintComponent,
    LanguageSelectorComponent,
    MainMenuToolbarComponent,
    MessagesAlertComponent,
    MinorElectivesChoiceStepComponent,
    MinorElectivesConfirmStepComponent,
    MinorElectivesCoursesChoiceComponent,
    MinorElectivesCoursesComponent,
    MinorElectivesCoursesConfirmComponent,
    MinorElectivesDetailComponent,
    NewDraftModalComponent,
    PlanElementComponent,
    RenameCopyDraftModalComponent,
    ScheduleCourseModalComponent,
    SearchableSelectComponent,
    SelectPlanComponent,
    ShowHistoryModalComponent,
    StatusMessageComponent,
    StudyPointsComponent,
    SubmitPlanModalComponent,
    TimeslotComponent,
    TooltipLabelComponent,
  ],
  exports: [
    AddCourseListHeaderComponent,
    CollapsableTextComponent,
    CourseActionButtonComponent,
    CourseActionMenuComponent,
    CourseGroupActionMenuComponent,
    CourseDataGroupComponent,
    CourseDetailComponent,
    CourseItemComponent,
    JsonPrettyPrintComponent,
    LanguageSelectorComponent,
    MainMenuToolbarComponent,
    MessagesAlertComponent,
    PlanElementComponent,
    StatusMessageComponent,
    StudyPointsComponent,
    TimeslotComponent,
    TooltipLabelComponent,
  ],
})
export class SharedModule {}
