export const PARAM_DISCLAIMER = "PLANAPP-DISCLAIMER";
export const PARAM_MINORS = "MINOREN";
export const PARAM_SPECIALISATIONS = "SPECIALISATIES";
export const PARAM_INSTITUTION = "INSTELLING";
export const PARAM_RESUBMIT_VALIDATED_PLAN = "GOEDGEKEURD-VOORSTEL-OPNIEUW-INDIENEN";
export const PARAM_PLANAPP_DISCLAIMER = "PLANAPP-DISCLAIMER";
export const PARAM_PLANAPP_LOGO = "INSTELLING";
export const PARAM_STUDENT_APPSRV_URL = "STUDENT-APPSRV-URL";

export type ConfigItem = {
  parameter: string;
  value: string;
};
