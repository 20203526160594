import { OnInit, Component, Input } from "@angular/core";
import { PlanHistoryDetails, PlanHistoryItem, PlanHistory } from "@core/domain/plan-history";
import { PlanHistoryService } from "@core/services/plan-history.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pa-history-info",
  templateUrl: "./history-info.component.html",
  styleUrls: ["./history-info.component.scss"],
})
export class HistoryInfoComponent implements OnInit {
  @Input() history!: PlanHistory;
  selectedItem: PlanHistoryItem | undefined;
  historyDetails: PlanHistoryDetails | undefined;
  historyItemsOpen = false;

  constructor(
    private planHistoryService: PlanHistoryService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.selectedItem = undefined;

    // If history has items, select the first one by default
    if (this.history && this.history.items?.length > 0) {
      this.selectItem(this.history.items[0]);
    }
  }

  selectItem(item: PlanHistoryItem): void {
    if (item) {
      this.selectedItem = item;
      this.fetchPlanHistoryDetails(this.selectedItem);
    }
    this.historyItemsOpen = false;
  }

  getHistoryItemName(item?: PlanHistoryItem): string {
    if (item?.statusDescription && item.statusDescription.length > 0) {
      return item.decisionDate && item.decisionDate.length > 0
        ? `${item.statusDescription} (${item.decisionDate})`
        : item.statusDescription;
    }

    return this.translate.instant("schedule.history.selectHistoryItem");
  }

  private fetchPlanHistoryDetails(item: PlanHistoryItem) {
    // prettier-ignore
    this.planHistoryService.fetchPlanHistoryDetails(item.splaId, item.hsplId, item.studentNr)
      .subscribe(details => {
        this.historyDetails = details;
      });
  }
}
