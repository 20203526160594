<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        <span>
          <b>
            {{ title }}
            @if (profile) {
              {{ profile.profileName }}
            }
          </b>
        </span>
      </ion-title>
    </ion-button>
    <ion-button fill="clear" slot="end" (click)="dismiss()" [attr.aria-label]="'ariaLabel.closeButton' | translate">
      <ion-icon icon="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  @if (profile) {
    <pa-choice-minor-electives [profile]="profile" [step]="currentStep" />
  }
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-padding-horizontal">
    @switch (currentStep) {
      @case (ProfileModalStep.Choice) {
        <ion-button slot="end" color="success" [disabled]="isNextButtonDisabled()" (click)="nextPressed()">
          {{ "general.btnNext" | translate }}
        </ion-button>
      }
      @case (ProfileModalStep.Confirm) {
        <ion-button fill="clear" slot="start" color="success" (click)="backPressed()">
          {{ "general.btnPrevious" | translate }}
        </ion-button>
        <ion-button slot="end" color="success" [disabled]="isSubmitButtonDisabled()" (click)="submitPressed()">
          {{ "choiceMinorOrElectives.btnSubmit" | translate }}
        </ion-button>
      }
      @default {
        Oops...
      }
    }
  </ion-toolbar>
</ion-footer>
