import { inject, Injectable } from "@angular/core";
import { RouterStateSnapshot } from "@angular/router";
import { User } from "@core/domain/user";
import { AuthService } from "@core/services/auth.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserResolver {
  private authService = inject(AuthService);

  resolve(_state: RouterStateSnapshot): Observable<User> | Promise<User> | User {
    return this.authService.getOrFetchUser();
  }
}
