<div class="message">
  @if (melding) {
    <ion-icon [icon]="icon" class="message-icon error-icon" />
    <span class="message-text">
      @if (examTypeDescription) {
        {{ examTypeDescription }} -
      }
      {{ melding.message }}
    </span>
  }
</div>
